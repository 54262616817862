import {
    Button,
    ButtonGroup,
    FormControl,
    FormErrorMessage,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    NumberInputField,
    useDisclosure
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { useEffect, useState } from "react";
import { PersonaModel } from "../../models/persona.model";
import { useHttpClient } from "../../utils/http.utils";

interface PaymentMethodFormProps {
    isOpen: boolean;
    persona: PersonaModel;
    mode: "health" | "ltt" | "credit";
    onClose: () => void;
}
export default function PersonaDonateModal(props: PaymentMethodFormProps) {
    const donateDisclosure = useDisclosure();
    const { donatePersonaHealthPointsApi, donateTransferFundExternalEntityApi } = useHttpClient();

    const [isSaving, setIsSaving] = useState(false);

    const getModeLabel = () => {
        switch (props.mode) {
            case "credit":
                return "T$";
            case "health":
                return "Health";
            case "ltt":
                return "TIX";
            default:
                return "";
        }
    };

    useEffect(() => {
        if (props.isOpen) {
            donateDisclosure.onOpen();
        } else {
            donateDisclosure.onClose();
        }
    }, [props.isOpen]);

    const closeFormModal = () => {
        donateDisclosure.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    /**
     * Form validators
     */
    const validateRequired = (key, value) => {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    };

    const onSubmitForm = async (values, _actions) => {
        setIsSaving(true);
        switch (props.mode) {
            case "credit":
                await donateTransferFundExternalEntityApi({
                    _persona: props.persona.id,
                    _amount: values.amount,
                    _currency: "credit"
                });
                break;
            case "health":
                await donatePersonaHealthPointsApi({
                    _persona: props.persona.id,
                    _amount: values.amount
                });
                break;
            case "ltt":
                await donateTransferFundExternalEntityApi({
                    _persona: props.persona.id,
                    _amount: values.amount,
                    _currency: "ltt"
                });
                break;
            default:
                return "";
        }
        setIsSaving(false);
    };

    return (
        <Modal
            isOpen={donateDisclosure.isOpen}
            onClose={closeFormModal}
            closeOnOverlayClick={!isSaving}
            size="md"
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <Formik
                    initialValues={{
                        amount: 0
                    }}
                    onSubmit={onSubmitForm}
                >
                    {(_props) => (
                        <Form>
                            <ModalHeader>Donate {getModeLabel()}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Field
                                    name="amount"
                                    validate={(e) => validateRequired("Amount", e)}
                                >
                                    {({ field, form }) => (
                                        <FormControl
                                            mb={2}
                                            isInvalid={form.errors.amount && form.touched.amount}
                                        >
                                            <p className="text-[13px] font-medium mb-1">Amount</p>
                                            <NumberInput min={1}>
                                                <NumberInputField
                                                    prefix="$"
                                                    {...field}
                                                    placeholder="Amount"
                                                    size={"sm"}
                                                />
                                            </NumberInput>
                                            <FormErrorMessage>
                                                {form.errors.amount}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </ModalBody>

                            <ModalFooter>
                                <ButtonGroup spacing={3}>
                                    <Button variant="ghost" onClick={closeFormModal}>
                                        Close
                                    </Button>
                                    <Button
                                        isLoading={isSaving}
                                        loadingText="Saving.."
                                        type="submit"
                                        color="white"
                                        colorScheme="brand"
                                    >
                                        Save
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
}
