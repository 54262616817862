/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable array-callback-return */
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import hotToast from "react-hot-toast";
import { AutoInteractionMessage } from "../models/collaboration.model";
import FileUploadControl from "./upload/FileUploadControl";

interface props {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onUserInputSubmit: Function;
    collaborationAppId: string;
    message: AutoInteractionMessage;
}

export default function InteractiveInput({
    onUserInputSubmit,
    collaborationAppId,
    message
}: props) {
    const textRef = useRef(null);
    // const fileRef = useRef(null);
    // const { uploadFile } = useHttpClient();
    const [userInputQuestions, setUserInputQuestions] = useState<any>([]);
    const [userResponses, setUserResponses] = useState<any>({});
    const [activeQuestion, setActiveQuestion] = useState<any>(0);

    useEffect(() => {
        const constructQuestionsArray = () => {
            const result: any = [];
            Object.entries(message!.raw_content!.user_inputs).map(([key, value]: any) => {
                return result.push({
                    key,
                    ...value
                });
            });
            setUserInputQuestions(result);
        };
        constructQuestionsArray();
    }, []);

    const inputVerification = (key) => {
        if (userResponses[key] && userResponses[key] !== undefined) {
            return true;
        }
        return false;
    };

    const handleUserInput = (key, event) => {
        setUserResponses((currentResponses) => ({
            ...currentResponses,
            [key]: event.target.value
        }));
    };

    const handleQuestionForwarding = () => {
        // Check if the question is required
        const question: any = userInputQuestions[activeQuestion];

        // if the question is not required, move to the next question
        if (question.required === false) {
            setActiveQuestion(activeQuestion + 1);
            return;
        }
        // If the question is required, check it has been answered
        if (inputVerification(question.key)) {
            setActiveQuestion(activeQuestion + 1);
            return;
        } else {
            hotToast.error(`Please provide an input for ${question.label} `);
        }
    };

    // const handleFileChange = async (label, event) => {
    //   const file = event.target.files[0];
    //   await uploadFile(file).then(res => (
    //     setUserResponses(currentResponses => ({ ...currentResponses, [label]: res.file_name }))
    //   )).catch(error => (
    //     hotToast.error("Error Uploading file. Please try again.")
    //   ))
    // };

    const isDisabled = () => {
        const currentQuestion: any = userInputQuestions[activeQuestion];
        return currentQuestion?.required ?? false;
    };

    const onFileUpload = async (data, key) => {
        setUserResponses((currentResponses) => ({ ...currentResponses, [key]: data.file_name }));
    };

    const onFileUploadError = async (error) => {
        console.log(error);
    };

    return (
        <div className="w-full bg-white rounded-[8px] px-4 py-2 pb-4">
            <p className="character-name text-[15px] font-semibold">{message.persona}</p>
            <p className="text-[14px] text-slate-600 mt-[1px] mb-[2px] pb-[4px]">
                Please provide the following inputs
            </p>
            <div className="border-l-[3px] rounded-[3px] py-2 pl-[12px] ">
                {userInputQuestions.map(({ key, label, description, file, required }) => {
                    if (key === userInputQuestions[activeQuestion]["key"]) {
                        return (
                            <div
                                key={key}
                                className="flex flex-col justify-start text-left items-start text-"
                            >
                                <div className="flex items-center gap-[5px]">
                                    <h4 className="text-[14px] font-semibold">{label}</h4>
                                    {required && (
                                        <p className="text-red-400 font-medium text-[11.8px]">
                                            Required
                                        </p>
                                    )}
                                </div>
                                <p
                                    title={description}
                                    className="text-[13px] overflow-2-lines text-slate-500 mb-[8px]"
                                >
                                    {description}
                                </p>
                                {file === true && (
                                    <FileUploadControl
                                        onComplete={(data) => onFileUpload(data, key)}
                                        onError={onFileUploadError}
                                    />
                                    // <input
                                    //   type="file"
                                    //   ref={fileRef}
                                    //   autoFocus
                                    //   placeholder={`Enter ...`}
                                    //   accept="*"
                                    //   onChange={event => handleFileChange(key, event)}
                                    //   className='w-full px-3 py-2 h-12 border-gray-300 rounded-md border' />
                                )}

                                {(!file || file === false) && (
                                    <input
                                        type="text"
                                        ref={textRef}
                                        autoFocus
                                        placeholder={`Enter ${label}`}
                                        onChange={(event) => handleUserInput(key, event)}
                                        className="w-full px-3 py-2 h-[40px] my-2 border-gray-300 hover:outline-blue-400 rounded-md border max-w-[600px]"
                                    />
                                )}
                            </div>
                        );
                    }
                })}
            </div>

            <div className="w-full mt-[10px]">
                {activeQuestion < userInputQuestions.length - 1 && (
                    <ButtonGroup spacing={"2"}>
                        <Button
                            onClick={handleQuestionForwarding}
                            size="sm"
                            background="blue.50"
                            color="blue.500"
                            // isDisabled={ }
                        >
                            Next
                        </Button>
                        <Button
                            onClick={handleQuestionForwarding}
                            size="sm"
                            background="gray.100"
                            color="gray.900"
                            isDisabled={isDisabled()}
                        >
                            Skip
                        </Button>
                    </ButtonGroup>
                )}
                {activeQuestion === userInputQuestions.length - 1 && (
                    <Button
                        onClick={() =>
                            onUserInputSubmit({
                                collaboration_app_id: collaborationAppId,
                                tool_identifier: message!.raw_content!.tool_identifier,
                                user_inputs: { ...userResponses }
                            })
                        }
                        size="sm"
                        background="blue.50"
                        color="blue.500"
                    >
                        Submit
                    </Button>
                )}
            </div>
        </div>
    );
}
