import React from "react";

interface SideMenuCardProps {
    title: string;
    leftIcon?: React.ReactElement;
    isActive?: boolean;
    rightIcon?: React.ReactElement;
    onClick?: () => void;
    onRightActionClick?: () => void;
}
export default function SideMenuCard(props: SideMenuCardProps) {
    function emitClick() {
        if (typeof props.onClick === "function") {
            props.onClick();
        }
    }

    function emitRightActionClick() {
        if (typeof props.onRightActionClick === "function") {
            props.onRightActionClick();
        }
    }

    return (
        <div title={props.title} className={`side-menu-card ${props.isActive ? "focus" : ""}`}>
            <div
                onClick={emitClick}
                className={`menu-leading ${props.leftIcon ? "with-icon" : ""}`}
            >
                {props.leftIcon && <span className="menu-icon">{props.leftIcon}</span>}
                <p className="menu-name">{props.title}</p>
            </div>

            <div className="action">
                {props.rightIcon && (
                    <button onClick={emitRightActionClick}>{props.rightIcon}</button>
                )}
            </div>
        </div>
    );
}
