export class Validators {
    static validateEmail(email) {
        if (!email) {
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+[^.]$/;
        return emailRegex.test(email);
    }
    static validateRequired(key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }
}
