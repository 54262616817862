/* eslint-disable no-undef */
/**
 * Function to download data as a JSON file
 * @param fileName - Name of the file to be downloaded
 * @param jsonData - Data to be saved in the file
 */

const downloadFile = (fileName: string, jsonData: object): void => {
    // Ensure the jsonData is an object
    if (typeof jsonData !== "object" || jsonData === null) {
        console.error("Provided data is not a valid object");
        return;
    }

    // Create file in the browser
    const json = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // Create "a" HTML element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();

    // Clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};

export default downloadFile;
