import {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { TbBulb } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import { PersonaModel } from "../../models/persona.model";
import { PersonaState, PersonaFormState, updatePersona } from "../../store/slices/persona.slice";
import { useHttpClient } from "../../utils/http.utils";
import { ProfileAvatar } from "../Avatar";
import { DeleteConfirmation } from "../utils/ConfirmationModals";

interface PersonaHeaderProps {
    authUser: any;
    onEdit?: () => void;
    onPortalClick?: (id: string) => void;
    onKill?: (id: string) => void;
}

const PersonaHeader = ({ onPortalClick, onKill }: PersonaHeaderProps) => {
    const {
        updatePersonaApi,
        deletePersonaApi,
        createCancellationResourceApi,
        deleteCancellationResourceApi,
        fetchCancellationResourceApi,
        getResourceContractApi
    } = useHttpClient();
    const toast = useToast();
    const [isChangingStatus, setIsChangingStatus] = React.useState(false);
    const [isKillingPersona, _setIsKillingPersona] = React.useState(false);
    // selected persona
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const personaFormState: PersonaFormState = personaState.personaAttributesForm;
    const dispatch = useDispatch();
    const activatePersonaModal = useDisclosure();
    const [isPersonaCancelled, setIsPersonaCancelled] = useState<boolean>(false);
    const [buttonAction, setButtonAction] = useState("");

    useEffect(() => {
        const getResourceCancellation = async () => {
            const cancellationRecords = await fetchCancellationResourceApi({
                resource_id: personaFormState?.id
            });

            if (cancellationRecords && cancellationRecords.length > 0) {
                setIsPersonaCancelled(true);
            }
        };

        if (personaFormState?.id) {
            getResourceCancellation();
        }

        if (personaFormState?.status === "cancelled") {
            setIsPersonaCancelled(true);
        }
    }, [personaFormState?.id]);

    const handleKillPersona = (persona: PersonaModel) => {
        if (persona.client) {
            DeleteConfirmation({
                title: "Kill Persona",
                message:
                    "This persona can only be killed from the client management screen. Do you wish to proceed?",
                keyword: "Redirect"
            }).then(async (isConfirmed) => {
                if (isConfirmed) {
                    const contractRecord = await getResourceContractApi({
                        resourceId: persona.id,
                        resourceType: "persona"
                    });

                    if (contractRecord?.contract_id) {
                        // eslint-disable-next-line no-undef, @typescript-eslint/no-non-null-assertion
                        window.location.href = `${process.env.REACT_APP_ONE_UI_URL}/app/commerce/contracts/${contractRecord.contract_id}`;
                    } else {
                        toast({
                            title: "No contract found",
                            description: "Could not access the contract for this persona",
                            status: "error"
                        });
                    }
                }
            });
        } else {
            DeleteConfirmation({
                title: "Kill Persona",
                message:
                    "Are you sure you want to kill this persona? Confirm this action by typing 'DELETE' in the input below."
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    deletePersonaApi(persona.id as string).then(() => {
                        if (typeof onKill === "function") {
                            onKill(persona.id as string);
                        }
                    });
                }
            });
        }
    };

    const handleClickPortal = (id: string) => {
        if (typeof onPortalClick === "function") {
            onPortalClick(id);
        }
    };

    const changePersonaStatus = (newStatus: "draft" | "active") => {
        setIsChangingStatus(true);

        updatePersonaApi({
            id: personaFormState.id,
            status: newStatus
        })
            .then(() => {
                toast({
                    title: "Persona status updated"
                });
                dispatch(updatePersona({ id: personaFormState.id, status: newStatus }));
            })
            .catch((error) => {
                console.log("Error updating persona status:", error);
                toast({
                    title: "An error occurred",
                    status: "error"
                });
            })
            .finally(() => {
                setIsChangingStatus(false);
                activatePersonaModal.onClose();
            });
    };

    const statusActions = {
        activate: {
            title: "Activate Persona",
            description:
                "By activating a persona, your subscription will be extended by an additional $10.",
            buttonText: "Activate",
            newStatus: "active" as const
        },
        cancel: {
            title: "Cancel Persona",
            description:
                "Are you sure? Cancelling the Persona will also schedule all associated Skills for cancellation.",
            buttonText: "Cancel",
            newStatus: "cancelled" as const
        },
        resume: {
            title: "Resume Persona",
            description: "Are you sure you want to resume this persona?",
            buttonText: "Resume",
            newStatus: "active" as const
        }
    };

    const cancelPersona = () => {
        setIsChangingStatus(true);

        if (personaFormState.id) {
            createCancellationResourceApi(personaFormState.id, "persona")
                .then(() => {
                    toast({
                        title: "Persona has been scheduled for cancellation"
                    });
                    setIsPersonaCancelled(true);
                })
                .catch((error) => {
                    console.log("Error cancelling persona:", error);
                    toast({
                        title: "An error occurred",
                        status: "error"
                    });
                });
        }
        setIsChangingStatus(false);
        activatePersonaModal.onClose();
    };

    const resumePersona = () => {
        setIsChangingStatus(true);

        if (personaFormState.id) {
            deleteCancellationResourceApi(personaFormState.id, "persona")
                .then(() => {
                    toast({
                        title: "Persona resumed"
                    });
                    setIsPersonaCancelled(false);

                    dispatch(updatePersona({ id: personaFormState.id, status: "active" }));
                })
                .catch((error) => {
                    console.log("Error resuming persona:", error);
                    toast({
                        title: "An error occurred",
                        status: "error"
                    });
                });
        }
        setIsChangingStatus(false);
        activatePersonaModal.onClose();
    };

    const getModalProps = () => {
        switch (buttonAction) {
            case "activate":
                return statusActions.activate;
            case "cancel":
                return statusActions.cancel;
            case "resume":
                return statusActions.resume;
            default:
                return statusActions.activate;
        }
    };

    const modalProps = getModalProps();

    return (
        <div
            style={{ margin: 0 }}
            className="toolbar px-8 mb-0 flex gap-2 justify-between items-center"
        >
            <div className="flex items-center">
                <div className="flex">
                    <div className="avatar">
                        <ProfileAvatar size="35px" />
                    </div>
                </div>
                <div className=" pl-3">
                    <p className="text  text-lg capitalize ">
                        {personaFormState.first_name + " " + personaFormState.last_name}
                        &nbsp;
                        <span className=" text-xs font-light">{personaFormState.type}</span>
                    </p>
                </div>
            </div>
            <div className="actions">
                {personaFormState.is_general_entity !== "yes" && (
                    <ButtonGroup>
                        <Button
                            onClick={() => handleClickPortal(personaFormState.id as string)}
                            size="sm"
                            variant="outline"
                        >
                            Portal
                        </Button>

                        {isPersonaCancelled || personaFormState.status === "cancelled" ? (
                            <Button
                                size="sm"
                                isLoading={isChangingStatus}
                                loadingText="Changing status.."
                                colorScheme="gray"
                                onClick={() => {
                                    setButtonAction("resume");
                                    activatePersonaModal.onOpen();
                                }}
                            >
                                Resume
                            </Button>
                        ) : null}

                        {personaFormState.status === "active" || isPersonaCancelled ? (
                            <Button
                                size="sm"
                                isLoading={isChangingStatus}
                                loadingText="Changing status.."
                                colorScheme="gray"
                                variant="outline"
                                onClick={() => {
                                    setButtonAction("cancel");
                                    activatePersonaModal.onOpen();
                                }}
                                isDisabled={isPersonaCancelled}
                            >
                                Cancel
                            </Button>
                        ) : null}

                        {personaFormState.status === "draft" && (
                            <Button
                                size="sm"
                                isLoading={isChangingStatus}
                                loadingText="Changing status.."
                                colorScheme="gray"
                                variant="outline"
                                onClick={() => {
                                    setButtonAction("activate");
                                    activatePersonaModal.onOpen();
                                }}
                            >
                                Activate
                            </Button>
                        )}

                        <Button
                            onClick={() => handleKillPersona(personaFormState)}
                            size="sm"
                            colorScheme="red"
                            variant="outline"
                            isLoading={isKillingPersona}
                            loadingText="Killing persona.."
                        >
                            Kill
                        </Button>
                    </ButtonGroup>
                )}
            </div>
            {/* Activate Persona  Modals*/}

            <Modal
                size="xl"
                isOpen={activatePersonaModal.isOpen}
                onClose={activatePersonaModal.onClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <Formik
                        initialValues={[]}
                        onSubmit={() => {
                            if (buttonAction === "activate") {
                                changePersonaStatus("active");
                            } else if (buttonAction === "cancel") {
                                cancelPersona();
                            } else if (buttonAction === "resume") {
                                resumePersona();
                            }
                        }}
                    >
                        {(_props) => (
                            <Form>
                                <ModalHeader>{modalProps.title}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <div className="mb-3 rounded-[8px] py-2 px-4 flex items-center gap-[10px] bg-yellow-50 border border-yellow-500">
                                        <TbBulb className="text-yellow-500" size="36px" />
                                        <p className="text-yellow-900">{modalProps.description}</p>
                                    </div>
                                </ModalBody>

                                <ModalFooter display="flex" justifyContent="center">
                                    <Button
                                        loadingText="Changing.."
                                        mt={4}
                                        type="submit"
                                        variant="brand"
                                        isLoading={isChangingStatus}
                                        background={"#1E1E1E"}
                                        color={"#FFF"}
                                        width={"100%"}
                                    >
                                        {
                                            statusActions[
                                                buttonAction === "cancel"
                                                    ? "cancel"
                                                    : buttonAction === "activate"
                                                      ? "activate"
                                                      : "resume"
                                            ].buttonText
                                        }
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default PersonaHeader;
