import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { PiTreeStructure } from "react-icons/pi";
import { useHttpClient } from "../../utils/http.utils";
import ConfigCards from "./ConfigCards";

type ConfigureSkillProps = {
    collab?: any;
    selectedPersona?: any;
    selectedSkills?: any;
    replaceValue?: boolean;
};

interface ArgumentConfig {
    type: string;
    required: boolean;
    parameter_type: string;
    description?: string;
    test?: boolean;
    item_type?: string;
}

interface SelectedTool {
    tool_id: string;
    tool_name: string;
    package_name: string;
    arguments_object: Record<string, ArgumentConfig>;
    description: string;
}

interface DisplayNode {
    title: string;
    description: string;
    arguments: string[];
}

interface ConfigData {
    selected_tool: SelectedTool;
    display: {
        nodes: DisplayNode[];
    };
}

export type InitialValuesType = Record<
    string,
    boolean | string | number | Array<boolean | string | number>
>;

const ConfigureSkill = ({
    collab,
    selectedPersona,
    selectedSkills,
    replaceValue
}: ConfigureSkillProps) => {
    const [toolWishList, setToolWishList] = useState<ConfigData[]>([]);
    const [argumentObject, setArgumentObject] = useState<any[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const formikRef = useRef<FormikProps<any>>(null);
    const { updateGlobalCollaborationGroupApi } = useHttpClient();
    const warningDisclosure = useDisclosure();

    const toast = useToast();

    useEffect(() => {
        const setBaseSkillCells = async () => {
            setToolWishList(collab?.json_structure?.cells?.[0]?.tool_wishlist);
        };

        setBaseSkillCells();
        formikRef.current?.resetForm();
    }, [collab]);

    const transformValues = (values) => {
        const result = {};

        Object.entries(values).forEach(([key, value]) => {
            const match = key.match(/^tool_index_(\d+)_(.+)$/);
            if (match) {
                const [_, index, field] = match;
                if (!result[index]) {
                    result[index] = {};
                }
                result[index][field] = value;
            }
        });

        const arrayResult = Array.from(
            { length: Math.max(...Object.keys(result).map(Number)) + 1 },
            (_, i) => result[i] || {}
        );

        return arrayResult;
    };

    const updateCollabGroups = async (values, actions) => {
        setIsSaving(true);

        try {
            const argumentObject = transformValues(values);
            setArgumentObject(argumentObject);

            if (selectedPersona.lenght > 0 || selectedSkills.length > 0) {
                const data = await updateGlobalCollaborationGroupApi(
                    argumentObject,
                    selectedPersona,
                    selectedSkills,
                    replaceValue ?? false,
                    collab.rigid_app_code
                );

                if (data) {
                    toast({
                        title: "The selected skills have been updated successfully!",
                        status: "success"
                    });
                }
            } else {
                warningDisclosure.onOpen();
            }
        } catch (error) {
            toast({
                title: `Error in updating the selected cloned skills`,
                status: "error"
            });

            console.log("Error in updateCollabGroups", error);
        } finally {
            actions.resetForm();
            actions.setSubmitting(false);
            setIsSaving(false);
        }
    };

    const handleWarningConfirm = async () => {
        try {
            const data = await updateGlobalCollaborationGroupApi(
                argumentObject,
                selectedPersona,
                selectedSkills,
                replaceValue ?? false,
                collab.rigid_app_code
            );

            if (data) {
                toast({
                    title: "The selected skills have been updated successfully!",
                    status: "success"
                });
            }
        } catch (error) {
            console.log("Error in handleWarningConfirm", error);

            toast({
                title: `Error in updating the selected cloned skills`,
                status: "error"
            });
        } finally {
            warningDisclosure.onClose();
        }
    };

    return (
        <div className="pt-[10px] pl-[5px]">
            <div className="persona-preview border-b min-h-[100px] pb-4 mb-4 grid grid-cols-[auto_200px] mr-4">
                <div>
                    <div className="head mb-1 flex items-center gap-[10px]">
                        <p className="rotate-90">
                            <PiTreeStructure size={"24px"} />
                        </p>
                        <h1 className="text-[18px] font-medium text-gray-600 m-0">
                            Configure Skills Here
                        </h1>
                    </div>
                    <p className="date text-slate-600 mb-2">
                        Effortlessly manage configuration settings by updating multiple cloned
                        skills at once. Apply changes globally or target specific Personas or Skills
                        based on your selection.
                    </p>
                    <div className="name flex gap-1">
                        <p className="label">Selected Skill: </p>
                        <p className="value font-semibold">{collab.title}</p>
                    </div>
                </div>
            </div>

            <Formik initialValues={{}} onSubmit={updateCollabGroups} innerRef={formikRef}>
                {(formik) => (
                    <Form>
                        {toolWishList?.length > 0 &&
                            toolWishList.map((item, index) => (
                                <div key={index}>
                                    {item?.display?.nodes?.length > 0 &&
                                        item.display.nodes.map((node, nodeIndex) => (
                                            <ConfigCards
                                                key={nodeIndex}
                                                node={node}
                                                fieldPrefix={`tool_index_${index}_`}
                                                argumentsObject={
                                                    item.selected_tool.arguments_object
                                                }
                                                formik={formik}
                                            />
                                        ))}
                                </div>
                            ))}

                        <Button
                            type="submit"
                            className="mt-6 mb-4"
                            isLoading={isSaving}
                            loadingText="Saving.."
                            variant="solid"
                            colorScheme="brand"
                            size="sm"
                        >
                            Save
                        </Button>
                    </Form>
                )}
            </Formik>

            <Modal isCentered isOpen={warningDisclosure.isOpen} onClose={warningDisclosure.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader pb={1}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                        >
                            <img
                                src="https://assets.neoworlder.com/img/crisis.png"
                                alt="Warning"
                                style={{ width: "75px", height: "auto" }}
                            />
                        </Box>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pt={-5}>
                        <Box textAlign="center">
                            Are you sure? This action will be performed for all existing Skills of
                            the same type.
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            size="sm"
                            colorScheme="brand"
                            mr={3}
                            onClick={handleWarningConfirm}
                            isLoading={isSaving}
                            loadingText="Saving..."
                        >
                            Confirm
                        </Button>
                        <Button size="sm" onClick={warningDisclosure.onClose} variant="outline">
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default ConfigureSkill;
