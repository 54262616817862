/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from "dayjs";
const localizedFormat = require("dayjs/plugin/localizedFormat");
const utc = require("dayjs/plugin/utc");

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export function formatDate(date: string | number, format = "DD/MM/YYYY") {
    // @ts-ignore
    const localTime = dayjs.utc(date).local();
    if (format) {
        return localTime.format(format);
    }
    // @ts-ignore
    return localTime.format("LLL");
}

export function newUTCDate() {
    // @ts-ignore
    return dayjs().utc().format();
}
