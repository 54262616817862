interface MetaTags {
    title: string;
    description?: string;
    imageUrl?: string;
    disableTrailAppName?: boolean;
}
export function setMetaTags(meta: MetaTags) {
    // eslint-disable-next-line no-undef
    document.title = `${meta.title} ${!meta.disableTrailAppName ? "| NeoWorlder Life Lab" : ""}`;
}
