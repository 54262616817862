import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter } from "@chakra-ui/react";
import { RxDoubleArrowRight } from "react-icons/rx";
import { Button } from "../ui/button";

interface GoingBackToNeoworlderProps {
    isOpen: boolean;
    handleConfirm: () => void;
    onClose: () => void;
}

// eslint-disable-next-line no-undef
const GoingBackToNeoworlder: React.FC<GoingBackToNeoworlderProps> = (props) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
            <ModalOverlay />
            <ModalContent mx={4}>
                <ModalBody style={{ padding: "16px 24px" }}>
                    <div className="flex flex-col items-center pt-[16px] gap-2">
                        <div className="row flex items-center justify-center">
                            <div className="location">
                                <img
                                    src="https://assets.neoworlder.com/img/v1/lifelab_logo_black.png"
                                    alt="Life Lab"
                                    className="dark:hidden w-auto h-[40px]"
                                />
                                <img
                                    src="https://assets.neoworlder.com/img/v1/lifelab_logo_white.png"
                                    alt="Life Lab"
                                    className="hidden dark:block w-auto h-[40px]"
                                />
                            </div>
                            <div className="arrow flex items-center">
                                <RxDoubleArrowRight size="30px" />
                            </div>
                            <div className="destination">
                                <img
                                    src="https://assets.neoworlder.com/img/neoworlder_logo_black.png"
                                    alt="@NeoWorlder"
                                    className="dark:hidden w-auto h-[55px]"
                                />
                                <img
                                    src="https://assets.neoworlder.com/img/neoworlder_logo_white.png"
                                    alt="@NeoWorlder"
                                    className="hidden dark:block w-auto h-[55px]"
                                />
                            </div>
                        </div>
                        <p className="text-md text-gray-600">
                            You will be redirected back to NeoWorlder, any unsaved progress will be
                            lost
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter className="flex justify-end gap-2">
                    <Button size="sm" variant="ghost" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button size="sm" variant="default" onClick={props.handleConfirm}>
                        Continue
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default GoingBackToNeoworlder;
