/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    Button,
    IconButton,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Spinner,
    Tag,
    TagCloseButton,
    TagLabel,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { cloneDeep } from "lodash";
import { useEffect, useCallback, useState } from "react";
import { IoFilterOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import ReactSelect, { SingleValue } from "react-select";
import ArinLayout from "../../components/ArinLayout";
import "../../styles/ApplicationsView.component.scss";
import BrainActivityPanel from "../../components/collaborate/BrainActivityPanel";
import CollaborationGroupCard from "../../components/collaborate/CollaborationGroupCard";
import {
    CollaborateState,
    setCollaborationAppDisplayFilter,
    setIsBaseSkill
} from "../../store/slices/collaborate.slice";
import { convertFiltersToSearchCriteria, useHttpClient } from "../../utils/http.utils";
import { setMetaTags } from "../../utils/seo.utils";
import { capitalizeWord } from "../../utils/strings.utils";
// import { DisplayFilters } from "../../models/filters.model";

export default function CollaborateView() {
    const collaborateState: CollaborateState = useSelector((state) => (state as any).collaborate);
    const { fetchCollaborationAppGroupApi } = useHttpClient();
    const [isSearching, setIsSearching] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setMetaTags({ title: "Skills" });
        // fetchData();
    }, []);

    // const fetchData = useCallback(async () => {
    //     if (!collaborateState.isLoading && collaborateState.collaborationGroups.length === 0) {
    //         await fetchCollaborationAppGroupApi();
    //     }
    // }, []);

    useEffect(() => {
        setIsSearching(true);
        console.log("isSearching", isSearching);

        const searchQuery = {
            search_criteria: {
                ...convertFiltersToSearchCriteria(collaborateState.displayFilters)
            }
        };

        if (collaborateState.isBase !== null) {
            searchQuery.search_criteria.is_base = collaborateState.isBase;
        }

        fetchCollaborationAppGroupApi(searchQuery).finally(() => setIsSearching(false));
    }, [collaborateState.displayFilters, collaborateState.isBase]);

    // Function to close the popover
    const closePopover = () => setIsFilterOpen(false);

    const onCellTypeFilterChange = (data: SingleValue<{ label: string; value: string }>) => {
        // find filter
        const filtersCopy = cloneDeep(collaborateState.displayFilters);

        // find filter
        const filterIndex = filtersCopy.findIndex((filter) => filter.filter === "type");

        if (filterIndex > -1) {
            filtersCopy[filterIndex].value = data!.value === "all" ? "" : data!.value;
        } else {
            filtersCopy.push({
                filter: "type",
                value: data!.value === "all" ? "" : data!.value
            });
        }

        dispatch(setCollaborationAppDisplayFilter(filtersCopy));
        closePopover();
    };

    const onBaseSkillFilterChange = (data: SingleValue<{ label: string; value: string }>) => {
        if (data!.value === "all") {
            dispatch(setIsBaseSkill(null as unknown as boolean));
        } else if (data!.value === "base") {
            dispatch(setIsBaseSkill(true));
        } else if (data!.value === "clone") {
            dispatch(setIsBaseSkill(false));
        }
        closePopover();
    };

    const clearFilter = (key: string) => {
        if (key === "is_base") {
            dispatch(setIsBaseSkill(null as unknown as boolean));
        } else {
            const filtersCopy = collaborateState.displayFilters.filter(
                (filter) => filter.filter !== key
            );
            dispatch(setCollaborationAppDisplayFilter(filtersCopy));
        }
    };

    const debouncedSearch = useCallback(
        debounce((value) => {
            let searchQuery = {};

            if (value) {
                searchQuery = {
                    search_criteria: {
                        title: {
                            contains: value
                        },
                        ...convertFiltersToSearchCriteria(collaborateState.displayFilters)
                    },
                    case_insensitive: true
                };
            }

            fetchCollaborationAppGroupApi(searchQuery);
        }, 500),
        []
    );

    const renderFilters = () => {
        // eslint-disable-next-line no-undef
        const filters: React.ReactElement[] = [];

        collaborateState.displayFilters
            .filter((filterMap) => !!filterMap.value)
            .forEach((filterMap, index) => {
                filters.push(
                    <Tag
                        key={`filter-${index}`}
                        size={"sm"}
                        variant="subtle"
                        borderRadius={"100px"}
                        colorScheme="gray"
                    >
                        <TagLabel>
                            {capitalizeWord(filterMap.filter)}: {capitalizeWord(filterMap.value)}
                        </TagLabel>
                        <TagCloseButton onClick={() => clearFilter(filterMap.filter)} />
                    </Tag>
                );
            });

        if (collaborateState.isBase !== null) {
            filters.push(
                <Tag
                    key="base-filter"
                    size={"sm"}
                    variant="subtle"
                    borderRadius={"100px"}
                    colorScheme="gray"
                >
                    <TagLabel>Skill: {collaborateState.isBase ? "Base" : "Clone"}</TagLabel>
                    <TagCloseButton onClick={() => clearFilter("is_base")} />
                </Tag>
            );
        }

        return filters;
    };

    const loadMoreCollabApps = () => {
        fetchCollaborationAppGroupApi({ page: collaborateState.collabGroupPage + 1 }, "append");
    };

    return (
        <ArinLayout>
            <div className="search-layout-wrapper">
                <div className="grid-column pl-2 pb-3">
                    <div className="sidebar-sm relative">
                        <div className="side-nav-sm">
                            <p className="title">Skills</p>
                            <p className="subtitle">Choose a skill to initialize</p>
                        </div>
                        <div className="sidebar-body">
                            <div className="grid grid-cols-[auto_32px] gap-[5px]">
                                <div className="input-group relative">
                                    <Input
                                        placeholder="Search Skills"
                                        onChange={(e) => debouncedSearch(e.target.value)}
                                        borderRadius={"4px"}
                                        size={"sm"}
                                    />
                                    <div className="absolute flex items-center bg-white pr-2 top-[1px] right-[1px] bottom-[1px] rounded-[0.375rem]">
                                        {collaborateState.isLoadingCollabApps && (
                                            <Spinner size={"sm"} />
                                        )}
                                    </div>
                                </div>
                                <div className="actions">
                                    <Popover isOpen={isFilterOpen} placement="bottom-end">
                                        <PopoverTrigger>
                                            <IconButton
                                                variant={"outline"}
                                                icon={<IoFilterOutline size={"18px"} />}
                                                aria-label={"Filter"}
                                                size={"sm"}
                                                onClick={() => setIsFilterOpen(!isFilterOpen)}
                                                title="Filter"
                                            />
                                        </PopoverTrigger>
                                        <Portal>
                                            <PopoverContent
                                                width={"250px"}
                                                shadow="0 6px 24px rgba(0, 0, 0, 0.12)"
                                            >
                                                <PopoverArrow />
                                                <PopoverHeader>Filters</PopoverHeader>
                                                {/* <PopoverCloseButton onCli /> */}
                                                <PopoverBody pb="20px">
                                                    <p className="label">Cell Types</p>
                                                    <ReactSelect
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                        onChange={onCellTypeFilterChange}
                                                        defaultValue={{
                                                            label: "All",
                                                            value: "all"
                                                        }}
                                                        options={[
                                                            {
                                                                label: "All",
                                                                value: "all"
                                                            },
                                                            {
                                                                label: "Malleable",
                                                                value: "malleable"
                                                            },
                                                            {
                                                                label: "Rigid",
                                                                value: "rigid"
                                                            }
                                                        ]}
                                                    />
                                                    <p className="label mt-4">Skill Types</p>
                                                    <ReactSelect
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                        onChange={onBaseSkillFilterChange}
                                                        defaultValue={{
                                                            label: "All",
                                                            value: "all"
                                                        }}
                                                        options={[
                                                            {
                                                                label: "All",
                                                                value: "all"
                                                            },
                                                            {
                                                                label: "Base",
                                                                value: "base"
                                                            },
                                                            {
                                                                label: "Clone",
                                                                value: "clone"
                                                            }
                                                        ]}
                                                    />
                                                    {/* <div className="flex justify-end pt-3 mt-4 pb-1">
                                                        <Button colorScheme="brand" size="sm">Save</Button>
                                                    </div> */}
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Portal>
                                    </Popover>
                                </div>
                            </div>
                            {renderFilters().length > 0 && (
                                <div className="filters pt-3 border-b pb-1">
                                    <p className="text-[12px] font-medium mb-[2px]">Filters</p>
                                    <Wrap spacing={2}>
                                        {renderFilters().map((filter, index) => (
                                            <WrapItem key={index}>{filter}</WrapItem>
                                        ))}
                                    </Wrap>
                                </div>
                            )}
                            {/*<div onClick={() => navigate("/skills/applications/invitees")}*/}
                            {/*     className="invites flex justify-between py-1 my-2">*/}
                            {/*    <button className="text-blue-500">Invites(3)</button>*/}
                            {/*    <button className="text-slate-500 text-lg"><BiChevronRight/></button>*/}
                            {/*</div>*/}
                            <div className="collaborations pt-3 relative">
                                <div className="">
                                    {collaborateState.collaborationGroups?.map(
                                        (collaborationAppGroup, index) => {
                                            return (
                                                <CollaborationGroupCard
                                                    key={index}
                                                    data={collaborationAppGroup}
                                                />
                                            );
                                        }
                                    )}
                                </div>

                                {collaborateState.collaborationGroups.length === 0 &&
                                    !collaborateState.isLoadingCollabApps && (
                                        <div className="empty-state">
                                            <div className="flex flex-col items-center absolute top-[50%] transform translate-y-[50%] px-10">
                                                <img
                                                    src="/no_collab.png"
                                                    className={"mx-auto h-16 mb-6"}
                                                    alt="No Collaboration apps found. Create new apps to execute."
                                                />
                                                <h1 className="title font-bold">Nothing to show</h1>
                                                <p className="text-sm text-center text-slate-500">
                                                    No Collaboration apps found. Create new apps to
                                                    execute.
                                                </p>
                                            </div>
                                        </div>
                                    )}
                            </div>

                            {collaborateState.collaborationGroups?.length > 2 && (
                                <div className="actions flex justify-center py-1">
                                    <Button
                                        onClick={loadMoreCollabApps}
                                        isLoading={collaborateState.isLoading}
                                        size="sm"
                                        variant="ghost"
                                    >
                                        <span className="text-blue-500">Load more</span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="grid-column">
                    <Outlet />
                </div>
                <div className="grid-column pr-3 pb-3">
                    {collaborateState.viewMode === "initialize" && <BrainActivityPanel />}
                </div>
            </div>
        </ArinLayout>
    );
}
