import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    ModalCloseButton
} from "@chakra-ui/react";
import { useEffect } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useSelector } from "react-redux";
import { PersonaFormState } from "../../store/slices/persona.slice";
import { formatDate } from "../../utils/date.utils";

interface NotarizedInformationModalProps {
    isOpen: boolean;
    onClose?: () => void;
}

export default function NotarizedInformationModal(props: NotarizedInformationModalProps) {
    const modalDisc = useDisclosure();
    const personaFormState: PersonaFormState = useSelector(
        (state: any) => state.persona.personaAttributesForm
    );

    useEffect(() => {
        if (props.isOpen) {
            modalDisc.onOpen();
        } else {
            modalDisc.onClose();
        }
    }, [props.isOpen]);

    const close = () => {
        modalDisc.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const openBlockChain = (hash) => {
        // eslint-disable-next-line no-undef
        window.open(`https://explorer.horizonafrica.io/transaction/${hash}`, "_blank");
    };

    return (
        <Modal
            size={"lg"}
            isCentered={true}
            isOpen={modalDisc.isOpen}
            onClose={close}
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {personaFormState.dna_list && personaFormState.dna_list?.length > 0 ? (
                        <h2 className="text">Past Notarization Information</h2>
                    ) : (
                        <h2 className="text">You have no past notarization</h2>
                    )}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt="0">
                    {personaFormState.dna_list?.length === 0 && (
                        <div className="empty">
                            <p className="text-slate0-500">Notarize now?</p>
                            <p className="text-slate0-500">Don’t see my notarization? Contact u</p>
                        </div>
                    )}
                    <div className="list min-h-[200px]">
                        {personaFormState.dna_list?.map((dna, _index) => (
                            <div key={dna.hash} className="tile mb-[18px]">
                                <h4 className="title font-semibold text-[15px] mb-1">
                                    {formatDate(dna.date, "DD MMM YYYY [at] HH:mm A")}
                                </h4>
                                <p className="text-slate-500 w-full whitespace-nowrap overflow-hidden text-ellipsis text-[13px]">
                                    Transaction Number: {dna.hash}{" "}
                                </p>
                                <Button
                                    onClick={() => openBlockChain(dna.hash)}
                                    size={"sm"}
                                    variant={"link"}
                                    colorScheme="blue"
                                    rightIcon={<HiOutlineExternalLink />}
                                >
                                    View on blockchain
                                </Button>
                            </div>
                        ))}
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
