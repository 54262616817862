/* eslint-disable no-undef */
import { useCallback, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useSearchParams } from "react-router-dom";
import PaywallModal from "./components/modals/PaywallModal";
import { setActiveOrganizationId } from "./store/slices/account.slice";
import ErrorBoundary from "./views/ErrorBoundary";
import KeycloakWrapper from "./views/keycloak-wrapper";

function App() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    // get organizationId from SESSION storage
    const initializeVendor = useCallback(() => {
        const activeOrg = window.sessionStorage.getItem("active_org");
        const vendorId = searchParams.get("vendor");

        if (!vendorId && !!activeOrg && typeof activeOrg === "string") {
            dispatch(setActiveOrganizationId(activeOrg));
        }

        if (!!vendorId && !activeOrg) {
            window.sessionStorage.setItem("active_org", vendorId);
            dispatch(setActiveOrganizationId(vendorId));
        }
    }, [dispatch, searchParams]);

    useLayoutEffect(() => {
        initializeVendor();
    }, [initializeVendor]);

    useEffect(() => {
        setTimeout(() => {
            const loadingEl = document.getElementById("loading-el");
            loadingEl?.remove();
        }, 2500);
    }, []);

    return (
        <div id="App">
            <KeycloakWrapper>
                <PaywallModal />
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </KeycloakWrapper>
        </div>
    );
}

export default App;
