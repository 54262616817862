import { FormControl, Switch, Textarea, IconButton, Input } from "@chakra-ui/react";
import { FormikProps, Field } from "formik";
import { useEffect, useRef, useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { SideBarFormArguments } from "../tools/form.model";

type ToolFormControlProps = {
    name: string;
    argument: SideBarFormArguments;
    showConvertToUserInputCheckbox?: boolean;
    onConvertToUserInput?: (checked: boolean) => void;
    formikProps: FormikProps<any>;
};

// eslint-disable-next-line no-undef
const FormikFormInputControl: React.FC<ToolFormControlProps> = ({
    name,
    argument,
    formikProps
}) => {
    const isInvalid = !!formikProps.errors[name] && !!formikProps.touched[name];
    const [showDescription, setShowDescription] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    // const templates = useSelector(selectTemplate);

    // const templateOptions = useMemo(() => {
    //     return templates.map((template: TemplateMetadataModel) => ({
    //         label: template.name,
    //         value: template
    //     }));
    // }, [templates]);

    useEffect(() => {
        /**
         * Clear the input field if form value is empty
         */
        if (textAreaRef.current) {
            // If the field value does not exist, clear the form+
            if (!formikProps.values[name]) {
                textAreaRef.current.value = "";
                textAreaRef.current.style.height = "40px";
                textAreaRef.current.style.overflow = "hidden";
            } else {
                const contentHeight = textAreaRef.current.scrollHeight;

                if (contentHeight >= 200) {
                    textAreaRef.current.style.overflowY = "auto";
                    textAreaRef.current.style.height = "200px";
                } else {
                    textAreaRef.current.style.height = contentHeight + "px";
                    textAreaRef.current.style.overflow = "hidden";
                }
            }
        }
    }, [name, formikProps.values]);

    // const showFileUPloadError = () => {
    //     hotToast.error(`Cannot upload file for the ${argument.label} argument. Please try again`);
    // };

    function autoExpand(e: any) {
        // Reset the height to a small size to shrink the size when deleting text
        e.target.style.height = "auto";
        // Set the height to the scroll height to expand to fit the content
        const contentHeight = e.target.scrollHeight;

        if (contentHeight >= 200) {
            e.target.style.height = "200px";
            e.target.style.overflowY = "auto";
        } else {
            e.target.style.height = contentHeight + "px";
            e.target.style.overflow = "hidden";
        }
    }

    switch (argument.type) {
        case "boolean":
            return (
                <FormControl className="pt-[16px] mt-[12px] pb-[10px]">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-[5px] items-center">
                            <p className={`text-[13px]  font-medium flex items-center gap-[4px]`}>
                                <span>{argument.label}</span>
                            </p>
                            {!!argument.description && (
                                <IconButton
                                    icon={<IoIosInformationCircleOutline size="18px" />}
                                    aria-label={"Description"}
                                    title="Show Description"
                                    className="cursor-pointer"
                                    size="xs"
                                    variant={"ghost"}
                                    onClick={() => setShowDescription(!showDescription)}
                                />
                            )}
                        </div>

                        <Field name={name}>
                            {({ field }) => (
                                <Switch
                                    {...field}
                                    checked={!!formikProps.values[name]}
                                    defaultChecked={!!formikProps.values[name]}
                                    isDisabled={argument.parameter_type === "user"}
                                />
                            )}
                        </Field>
                    </div>
                    {showDescription && (
                        <p className="text-[13px] mt-[4px] font-normal text-neutral-400">
                            {argument.description || `Toggle ${argument.label} status`}
                        </p>
                    )}
                </FormControl>
            );
        // case "file":
        //     return (
        //         <FormControl mb={2}>
        //             <div className="form-control-header">
        //                 <div className="mb-[8px]">
        //                     <div className="flex justify-between items-center">
        //                         <p
        //                             className={`text-[13px]  font-medium flex items-center gap-[4px]`}
        //                         >
        //                             <span>{argument.label}</span>
        //                         </p>
        //                         {!!argument.description && (
        //                             <IconButton
        //                                 icon={<IoIosInformationCircleOutline size="18px" />}
        //                                 aria-label={"Description"}
        //                                 title="Show Description"
        //                                 className="cursor-pointer"
        //                                 size="xs"
        //                                 variant={"ghost"}
        //                                 onClick={() => setShowDescription(!showDescription)}
        //                             />
        //                         )}
        //                     </div>
        //                     {argument.description && showDescription && (
        //                         <p className="text-[13px] font-normal text-neutral-400">
        //                             {argument.description}
        //                         </p>
        //                     )}
        //                 </div>
        //             </div>
        //             {/* TODO: USER THE the field hook */}
        //             <FileUploadControl
        //                 uploadType="tool_argument"
        //                 onComplete={({ file_name }) => formikProps.setFieldValue(name, file_name)}
        //                 onError={showFileUPloadError}
        //                 isDisabled={argument.parameter_type == "user"}
        //             />
        //         </FormControl>
        //     );
        // case "files_array":
        //     return (
        //         <FormControl mb={2}>
        //             <div className="form-control-header">
        //                 <div className="mb-[8px]">
        //                     <div className="flex justify-between items-center">
        //                         <p
        //                             className={`text-[13px]  font-medium flex items-center gap-[4px]`}
        //                         >
        //                             <span>{argument.label}</span>
        //                         </p>
        //                         {!!argument.description && (
        //                             <IconButton
        //                                 icon={<IoIosInformationCircleOutline size="18px" />}
        //                                 aria-label={"Description"}
        //                                 title="Show Description"
        //                                 className="cursor-pointer"
        //                                 size="xs"
        //                                 variant={"ghost"}
        //                                 onClick={() => setShowDescription(!showDescription)}
        //                             />
        //                         )}
        //                     </div>
        //                     {argument.description && showDescription && (
        //                         <p className="text-[13px] font-normal text-neutral-400">
        //                             {argument.description}
        //                         </p>
        //                     )}
        //                 </div>
        //             </div>
        //             {/* TODO: USER THE the field hook */}
        //             <MultipleFilesUploadControl
        //                 uploadType="tool_argument"
        //                 onComplete={({ file_names }) => formikProps.setFieldValue(name, file_names)}
        //                 onError={showFileUPloadError}
        //                 isDisabled={argument.parameter_type == "user"}
        //             />
        //         </FormControl>
        //     );
        // case "private_structured_db":
        //     return (
        //         <FormControl mb={6} isInvalid={isInvalid}>
        //             <div className="mb-[8px]">
        //                 <div className="flex justify-between items-center">
        //                     <p className={`text-[13px]  font-medium flex items-center gap-[4px]`}>
        //                         <span>{argument.label}</span>
        //                     </p>
        //                     {!!argument.description && (
        //                         <IconButton
        //                             icon={<IoIosInformationCircleOutline size="18px" />}
        //                             aria-label={"Description"}
        //                             title="Show Description"
        //                             className="cursor-pointer"
        //                             size="xs"
        //                             variant={"ghost"}
        //                             onClick={() => setShowDescription(!showDescription)}
        //                         />
        //                     )}
        //                 </div>
        //                 {argument.description && showDescription && (
        //                     <p className="text-[13px] font-normal text-neutral-400">
        //                         {argument.description}
        //                     </p>
        //                 )}
        //             </div>
        //             <Field name={name}>
        //                 {({ field }) => (
        //                     <FormikSelect
        //                         {...field}
        //                         id={name}
        //                         placeholder={`Select ${argument.label}`}
        //                         options={templateOptions}
        //                         isMulti={true}
        //                         className={`${isInvalid && "border-[#FC8181]"}`}
        //                         onChange={(selectedOptions) =>
        //                             formikProps.setFieldValue(name, selectedOptions)
        //                         }
        //                         onBlur={formikProps.handleBlur}
        //                         value={formikProps.values[name]}
        //                     />
        //                 )}
        //             </Field>
        //             {isInvalid && (
        //                 <p className="form-errors text-[14px] mt-[4px] text-red-300">
        //                     {formikProps.errors[name]?.toString()}
        //                 </p>
        //             )}
        //         </FormControl>
        //     );

        case "file":
        case "files_array":
        case "private_structured_db":
        case "array":
            return <></>;
        case "number":
            return (
                <FormControl mb={6} isInvalid={isInvalid}>
                    <div className="mb-[8px]">
                        <div className="flex justify-between items-center">
                            <p className={`text-[13px]  font-medium flex items-center gap-[4px]`}>
                                <span>{argument.label}</span>
                            </p>
                            {!!argument.description && (
                                <IconButton
                                    icon={<IoIosInformationCircleOutline size="18px" />}
                                    aria-label={"Description"}
                                    title="Show Description"
                                    className="cursor-pointer"
                                    size="xs"
                                    variant={"ghost"}
                                    onClick={() => setShowDescription(!showDescription)}
                                />
                            )}
                        </div>
                        {argument.description && showDescription && (
                            <p className="text-[13px] font-normal text-neutral-400">
                                {argument.description}
                            </p>
                        )}
                    </div>
                    <Field name={name}>
                        {({ field }) => (
                            <Input
                                {...field} // Formik field props
                                id={name}
                                placeholder={`Enter ${argument.label}`}
                                variant="filled"
                                className={`${isInvalid && "border-[#FC8181]"} resize-none overflow-hidden`}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                value={formikProps.values[name]}
                                type="number"
                                minHeight="40px"
                                height="auto"
                                isDisabled={argument.parameter_type === "user"}
                            />
                        )}
                    </Field>
                    {isInvalid && (
                        <p className="form-errors text-[14px] mt-[4px] text-red-300">
                            {formikProps.errors[name]?.toString()}
                        </p>
                    )}
                </FormControl>
            );
        // case "array":
        //     return (
        //         <Formik
        //             initialValues={{
        //                 forms: formikProps.values[name] || []
        //             }} // Add the select field to initial values
        //             onSubmit={() => {}}
        //         >
        //             {({ values }) => (
        //                 <>
        //                     <div className="flex gap-[5px] items-center">
        //                         <Box as="span" flex="1" textAlign="left" className="pb-4">
        //                             <h4 className="text-[15px]">{argument.label}</h4>
        //                             {argument.description && (
        //                                 <p className="text-[13px] font-normal text-gray-400">
        //                                     {argument.description}
        //                                 </p>
        //                             )}
        //                         </Box>
        //                     </div>
        //                     <FieldArray name="forms">
        //                         {({ remove, push }) => (
        //                             <Box mb={4}>
        //                                 <div className="mb-3">
        //                                     <Button
        //                                         colorScheme="blue"
        //                                         size="sm"
        //                                         leftIcon={<AddIcon />}
        //                                         onClick={() => push({ cell: "", tool: "" })}
        //                                     >
        //                                         Add Item
        //                                     </Button>
        //                                 </div>

        //                                 {values.forms!.length > 0 &&
        //                                     values.forms!.map((_item, index) => (
        //                                         <Box
        //                                             key={`form-box-${index}`}
        //                                             className="relative mt-4 mb-4 border-[1px] p-2 rounded-lg border-zinc-500 flex gap-1"
        //                                         >
        //                                             <FormControl>
        //                                                 {/* <p className="text-[14px]  mb-2 font-medium">Cell Identifier</p> */}
        //                                                 <Field
        //                                                     as={Input}
        //                                                     type={argument.item_type || "string"}
        //                                                     name={`forms.${index}`}
        //                                                     onBlur={() =>
        //                                                         formikProps.setFieldValue(
        //                                                             name,
        //                                                             values.forms
        //                                                         )
        //                                                     }
        //                                                 />
        //                                             </FormControl>
        //                                             <div className="flex justify-end right-[12px] top-[12px]">
        //                                                 <button
        //                                                     type="button"
        //                                                     onClick={() => remove(index)}
        //                                                     className="text-red-400"
        //                                                 >
        //                                                     <BsTrash size={"18px"} />
        //                                                 </button>
        //                                             </div>
        //                                         </Box>
        //                                     ))}
        //                             </Box>
        //                         )}
        //                     </FieldArray>
        //                     {/* <PreviousOutputAutoSave debounceMs={500} /> */}
        //                 </>
        //             )}
        //         </Formik>
        //     );
        default:
            return (
                <FormControl mb={6} isInvalid={isInvalid}>
                    <div className="mb-[8px]">
                        <div className="flex justify-between items-center">
                            <p className={`text-[13px]  font-medium flex items-center gap-[4px]`}>
                                <span>{argument.label}</span>
                            </p>
                            {!!argument.description && (
                                <IconButton
                                    icon={<IoIosInformationCircleOutline size="18px" />}
                                    aria-label={"Description"}
                                    title="Show Description"
                                    className="cursor-pointer"
                                    size="xs"
                                    variant={"ghost"}
                                    onClick={() => setShowDescription(!showDescription)}
                                />
                            )}
                        </div>
                        {argument.description && showDescription && (
                            <p className="text-[13px] font-normal text-neutral-400">
                                {argument.description}
                            </p>
                        )}
                    </div>
                    <Field name={name}>
                        {({ field }) => (
                            <Textarea
                                {...field} // Formik field props
                                id={name}
                                ref={textAreaRef}
                                placeholder={`Enter ${argument.label}`}
                                className={`${isInvalid && "border-[#FC8181]"} resize-none overflow-hidden`}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                value={formikProps.values[name]}
                                minHeight="40px"
                                height="auto"
                                rows={1}
                                isDisabled={argument.parameter_type === "user"}
                                onInput={autoExpand}
                            />
                        )}
                    </Field>
                    {isInvalid && (
                        <p className="form-errors text-[14px] mt-[4px] text-red-300">
                            {formikProps.errors[name]?.toString()}
                        </p>
                    )}
                </FormControl>
            );
    }
};

export default FormikFormInputControl;
