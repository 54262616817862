import { Checkbox } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "../../styles/MemoryDisplayCards.component.scss";

interface SegregatedSectorDisplayCardProps {
    title: string;
    description: string;
    hideCheckBox?: boolean;
    onChanged?: (checked: boolean) => void;
    onClick?: () => void;
    isSelected?: boolean;
}
export function SegregatedSectorDisplayCard(props: SegregatedSectorDisplayCardProps) {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        setIsChecked(props.isSelected || false);
    }, [props.isSelected]);

    const onChange = (checked: boolean) => {
        setIsChecked(checked);
        if (props.onChanged) {
            props.onChanged(checked);
        } // Call the onChanged function if it exists
    };

    const clickEvent = () => {
        if (typeof props.onClick === "function") {
            props.onClick();
        }
    };

    return (
        <div
            onClick={clickEvent}
            className={`sector-memory-display-card ${props.hideCheckBox ? "" : "with-checkbox"}`}
        >
            {!props.hideCheckBox && (
                <div className="pt-2">
                    <Checkbox isChecked={isChecked} onChange={(e) => onChange(e.target.checked)} />
                </div>
            )}
            <div className="">
                <p className="sector-title truncate mb-1" title={props.title}>
                    {props.title}
                </p>
                <p title={props.description} className="sector-description text-slate-500 text-sm">
                    {props.description}
                </p>
            </div>
        </div>
    );
}
