export function formatFileSize(bytes: number, decimalPoint: number) {
    if (bytes === 0) {
        return "0 Bytes";
    }
    const k = 1000,
        dm = decimalPoint || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatCurrency(value: number) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });

    return formatter.format(value);
}

export function formatReadableNumber(value: number, maxDecimalPlaces?: number) {
    let valueString: string;
    let integerPart: string;
    let fractionalPart = "";

    if (maxDecimalPlaces !== undefined) {
        valueString = value.toFixed(maxDecimalPlaces);
    } else {
        valueString = value.toString();
    }

    const valueParts = valueString.split(".");
    integerPart = valueParts[0] || "0";
    fractionalPart = valueParts.length > 1 && valueParts[1] ? "." + valueParts[1] : "";

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return integerPart + fractionalPart;
}
