import { formatCollaborateMessage } from "../../utils/collaborate/parser";
import { ProfileAvatar } from "../Avatar";

type UserChatProps = {
    userName: string;
    message: string;
};
const UserChat = ({ userName, message }: UserChatProps) => {
    return (
        <div className="user-msg mb-[12px] bg-blue-50 border border-blue-200 pt-2 pb-3 px-3 rounded text-black ml-[24px]">
            <div className="grid gap-2 grid-cols-[auto_35px]">
                <div className="">
                    <p className="">{userName}</p>
                    <p className="character-message mt-2 mb-1 text-slate-500">
                        {formatCollaborateMessage(message)}
                    </p>
                </div>
                <div className="pt-[5px]">
                    <ProfileAvatar />
                </div>
            </div>
        </div>
    );
};

export default UserChat;
