import { createBrowserRouter, Navigate } from "react-router-dom";
// import FindAnythingView from "../views/FindAnythingView";
// import FolderView from "../views/FolderView";
// import RefinementsView from "../views/RefinementsView";
import App from "../App";
// import ProfileView from "../views/account/ProfileView";
import ProtectedRoute from "../components/auth/ProtectedRoute";
// import StoreMemories from "../views/StoreMemories";
import RedirectAuth from "../components/auth/RedirectAuth";
import AppInitializerView from "../views/AppInitializerView";
import NotFoundView from "../views/NotFoundView";
import PersonasView from "../views/PersonasView";
// import OrganizationMembersView from "../views/account/organization/OrganizationMembersView";
import PromptEngineeringView from "../views/PromptEngineeringView";
import AccountRoot from "../views/account/AccountRoot";
// import AgentsView from "../views/account/AgentsView";
// import UsageView from "../views/account/UsageView";
// import SubscriptionOrganizationView from "../views/account/organization/OrganizationView";
// import PaymentMethodsView from "../views/account/organization/PaymentMethodsView";
// import SubscriptionHistoryView from "../views/account/organization/SubscriptionHistoryView";
import ApiKeysView from "../views/account/ApiKeysView";
import NeuralPathways from "../views/account/NeuralPathways";
// import SubcriptionView from "../views/account/SubcriptionView";
// import ThreadView from "../views/ThreadView";
import PerceptorSetup from "../views/account/PerceptorSetup";
import IdeationView from "../views/collaborate/CollabIdeationView";
import GlobalView from "../views/global/GlobalView";
import CreditPurchase from "../views/payments/CreditPurchase";
import SubscriptionCancelledView from "../views/payments/SubscriptionCancelledView";
import SubscriptionSuccessView from "../views/payments/SubscriptionSuccessView";
import CollaborateView from "../views/skills/CollaborateView";
// import LandingPage from "../views/LandingPage";

export enum ROUTES {
    ASK_ANYTHING = "/ask-anything",
    ASK_ANYTHING_THREAD = "/ask-anything/thread/:id",
    FOLDERS = "/folders",
    FOLDERS_ID = "/folders/:id",
    REFINEMENTS = "/refinements",
    STORE_MEMORIES = "/store-memories",
    PERSONAS = "/personas",
    GLOBAL = "/global",
    ACCOUNT = "/account",
    AGENTS = "/account/agents",
    USAGE = "/account/usage",
    API_KEYS = "/account/api-keys",
    SUBSCRIPTION = "/account/subscription",
    SUBSCRIPTION_ORGANIZATION = "/account/subscription/organization",
    SUBSCRIPTION_HISTORY = "/account/subscription/history",
    SUBSCRIPTION_PAYMENT_METHODS = "/account/subscription/payment-methods",
    BRAINS = "/account/brains",
    // PROFILE = "/account/profile",
    // MEMBERS = "/account/members",
    APPLY = "/skills",
    PERCEPTOR_SETUP = "/account/perceptors",
    APPLY_APPLICATIONS = "/skills/applications",
    APPLY_INVITEES = "/skills/applications/invitees",
    APPLY_IDEATION = "/skills/applications/ideation",
    PROMPT_ENGINEERING = "/skills/prompt-engineering",
    THREAD = "/ask-anything/thread/:id",
    LANDING_PAGE = "/landing-page",
    AUTHORIZATION_PAGE = "/authorization",
    INITIALIZER = "/initializer",
    PAYMENTS_SUBSCRIPTION_SUCCESS = "/payments/callback/subscription-success",
    PAYMENTS_SUBSCRIPTION_CANCELLED = "/payments/callback/subscription-cancelled",
    CREDIT_PURCHASE = "/payments/callback/credit-purchase"
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <RedirectAuth />
            },
            {
                path: ROUTES.INITIALIZER,
                element: <AppInitializerView />
            },
            // {
            //     path: ROUTES.LANDING_PAGE,
            //     element: <LandingPage />,
            // },
            // {
            //     path: ROUTES.OAUTH_CALLBACK,
            //     element: <OAuthCallback />,
            // },
            // {
            //     path: ROUTES.ASK_ANYTHING,
            //     element: (
            //         <ProtectedRoute>
            //             <FindAnythingView />
            //         </ProtectedRoute>
            //     ),
            // },
            // {
            //     path: ROUTES.ASK_ANYTHING_THREAD,
            //     element: <ThreadView />,
            // },
            // {
            //     path: ROUTES.FOLDERS,
            //     element: (
            //         <ProtectedRoute>
            //             <FolderView />
            //         </ProtectedRoute>
            //     ),
            // },
            // {
            //     path: ROUTES.FOLDERS_ID,
            //     element: (
            //         <ProtectedRoute>
            //             <FolderView />
            //         </ProtectedRoute>
            //     ),
            // },
            // {
            //     path: ROUTES.REFINEMENTS,
            //     element: (
            //         <ProtectedRoute>
            //             <RefinementsView />
            //         </ProtectedRoute>
            //     ),
            // },
            // {
            //     path: ROUTES.STORE_MEMORIES,
            //     element: (
            //         <ProtectedRoute>
            //             <StoreMemories />
            //         </ProtectedRoute>
            //     ),
            // },
            {
                path: ROUTES.PERSONAS,
                element: (
                    <ProtectedRoute>
                        <PersonasView />,
                    </ProtectedRoute>
                )
            },
            {
                path: ROUTES.GLOBAL,
                element: (
                    <ProtectedRoute>
                        <GlobalView />,
                    </ProtectedRoute>
                )
            },
            {
                path: ROUTES.APPLY_APPLICATIONS,
                element: (
                    <ProtectedRoute>
                        <CollaborateView />
                    </ProtectedRoute>
                ),
                children: [
                    // {
                    //     path: ROUTES.APPLY_INVITEES,
                    //     element: <InviteesView />,
                    // },
                    {
                        path: ROUTES.APPLY_IDEATION,
                        element: <IdeationView />
                    }
                ]
            },
            {
                path: ROUTES.PROMPT_ENGINEERING,
                element: (
                    <ProtectedRoute>
                        <PromptEngineeringView />
                    </ProtectedRoute>
                )
            },
            {
                path: ROUTES.ACCOUNT,
                element: (
                    <ProtectedRoute>
                        <AccountRoot />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "/account/",
                        element: <Navigate to={ROUTES.API_KEYS} replace />
                    },
                    // {
                    //     path: ROUTES.MEMBERS,
                    //     element: (
                    //         <ProtectedRoute>
                    //             <OrganizationMembersView />
                    //         </ProtectedRoute>
                    //     ),
                    // },
                    // {
                    //     path: ROUTES.PROFILE,
                    //     element: (
                    //         <ProtectedRoute>
                    //             <ProfileView />
                    //         </ProtectedRoute>
                    //     ),
                    // },
                    // {
                    //     path: ROUTES.AGENTS,
                    //     element: (
                    //         <ProtectedRoute>
                    //             <AgentsView />
                    //         </ProtectedRoute>
                    //     ),
                    // },
                    // {
                    //     path: ROUTES.USAGE,
                    //     element: (
                    //         <ProtectedRoute>
                    //             <UsageView />
                    //         </ProtectedRoute>
                    //     ),
                    // },
                    {
                        path: ROUTES.API_KEYS,
                        element: (
                            <ProtectedRoute>
                                <ApiKeysView />
                            </ProtectedRoute>
                        )
                    },
                    // {
                    //     path: ROUTES.SUBSCRIPTION,
                    //     element: (
                    //         <ProtectedRoute>
                    //             <SubcriptionView />
                    //         </ProtectedRoute>
                    //     ),
                    //     children: [
                    //         {
                    //             path: ROUTES.SUBSCRIPTION,
                    //             element: <Navigate to={ROUTES.SUBSCRIPTION_ORGANIZATION} replace />,
                    //         },
                    //         {
                    //             path: ROUTES.SUBSCRIPTION_ORGANIZATION,
                    //             element: (
                    //                 <ProtectedRoute>
                    //                     <SubscriptionOrganizationView />
                    //                 </ProtectedRoute>
                    //             ),
                    //         },
                    //         {
                    //             path: ROUTES.SUBSCRIPTION_HISTORY,
                    //             element: (
                    //                 <ProtectedRoute>
                    //                     <SubscriptionHistoryView />
                    //                 </ProtectedRoute>
                    //             ),
                    //         },
                    //         {
                    //             path: ROUTES.SUBSCRIPTION_PAYMENT_METHODS,
                    //             element: (
                    //                 <ProtectedRoute>
                    //                     <PaymentMethodsView />
                    //                 </ProtectedRoute>
                    //             ),
                    //         },
                    //     ],
                    // },
                    {
                        path: ROUTES.BRAINS,
                        element: (
                            <ProtectedRoute>
                                <NeuralPathways />
                            </ProtectedRoute>
                        )
                    },
                    {
                        path: ROUTES.PERCEPTOR_SETUP,
                        element: (
                            <ProtectedRoute>
                                <PerceptorSetup />
                            </ProtectedRoute>
                        )
                    }
                ]
            },
            {
                path: ROUTES.PAYMENTS_SUBSCRIPTION_SUCCESS,
                element: <SubscriptionSuccessView />
            },
            {
                path: ROUTES.PAYMENTS_SUBSCRIPTION_CANCELLED,
                element: <SubscriptionCancelledView />
            },
            {
                path: ROUTES.CREDIT_PURCHASE,
                element: (
                    <ProtectedRoute>
                        <CreditPurchase />
                    </ProtectedRoute>
                )
            }
        ]
    },
    {
        path: "*",
        element: <NotFoundView />
    }
]);

export default router;
