export function removeDuplicatesFromArray(arr) {
    return arr.reduce((uniqueArr, item) => {
        if (!uniqueArr.includes(item)) {
            uniqueArr.push(item);
        }
        return uniqueArr;
    }, []);
}

export function sortListByProperty(array, property, ascending = true) {
    array.sort((a, b) => {
        if (a[property] < b[property]) {
            return ascending ? -1 : 1;
        } else if (a[property] > b[property]) {
            return ascending ? 1 : -1;
        }
        return 0;
    });
    return array;
}
