import { Button, useToast } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { PersonaModel } from "../../models/persona.model";
import { updatePersona } from "../../store/slices/persona.slice";
import { useHttpClient } from "../../utils/http.utils";
import { getPersonaProfilePicture } from "../../utils/personas.utils";

interface PersonaProfilePhotoProps {
    persona: PersonaModel;
    // eslint-disable-next-line no-undef
    setIsPictureLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PersonaProfilePhoto({
    persona,
    setIsPictureLoading
}: PersonaProfilePhotoProps) {
    const profilePicRef = useRef(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const { uploadPersonaProfilePhotoApi } = useHttpClient();
    const dispatch = useDispatch();
    const toast = useToast();
    const [isPictureUploading, setIsPictureUploading] = useState(false);

    const togglePicker = () => {
        (profilePicRef.current as unknown as HTMLInputElement).click();
    };

    const setPhotoUploading = (isUploading: boolean) => {
        setIsPictureUploading(isUploading);
        setIsPictureLoading(isUploading);
    };

    const onPhotoSelected = async (e) => {
        setPhotoUploading(true);

        const file = e.target.files[0];
        const maxFileSize = 500 * 1024;

        if (file?.size > maxFileSize) {
            // Notify the user
            toast({
                title: "Unable to upload profile photo",
                description: "Image is too large",
                status: "error"
            });
        } else {
            // Upload the file to server and show progress on frontend.
            const data = await uploadPersonaProfilePhotoApi(file, persona.id as string);

            if (data && data.data.profile_picture_url) {
                if (imageRef.current) {
                    imageRef.current.src = data.data.profile_picture_url;
                }
                dispatch(
                    updatePersona({
                        id: persona.id as string,
                        profile_photo: data.data.profile_picture_url
                    })
                );
            }
        }

        setPhotoUploading(false);
    };

    return (
        <div className="profile-photo flex flex-col gap-[15px] items-center">
            <input
                style={{ display: "none" }}
                type="file"
                ref={profilePicRef}
                accept="image/jpeg, image/png"
                onChange={onPhotoSelected}
            />
            <img
                ref={imageRef}
                className="image-progress-clip h-[95px] w-[95px] rounded-full object-cover"
                src={getPersonaProfilePicture(persona)}
                alt=""
            />
            <Button
                onClick={togglePicker}
                variant="link"
                size={"xs"}
                colorScheme="blue"
                isDisabled={isPictureUploading}
            >
                {persona.profile_photo ? "Change Photo" : "Upload Profile Photo"}
            </Button>
        </div>
    );
}
