import { IconButton, Progress, Spinner } from "@chakra-ui/react";
import axios from "axios";
import React, { useRef, useState } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { IoRefresh } from "react-icons/io5";
import { TbRefreshAlert } from "react-icons/tb";
import KeycloakClient from "../../providers/auth/keycloak-client";
import { formatFileSize, getFileNameAndExtension, truncateText } from "../../utils/strings.utils";

interface FileUploadProps {
    isDisabled?: boolean;
    onComplete: (data) => void;
    onError: (error) => void;
}
const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const FileUploadControl: React.FC<FileUploadProps> = ({ onComplete, onError, isDisabled }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [drag, setDrag] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const fileUpload = async (file: File) => {
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            // set uploading state to true
            setIsUploading(true);
            // upload file to server
            httpClient
                .post(`/api/v1/file/upload-file`, formData, {
                    onUploadProgress: (progressEvent) => {
                        setUploadPercentage(
                            Number(
                                Math.round(
                                    (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
                                )
                            )
                        );
                    },
                    headers: {
                        Authorization: `Bearer ${await KeycloakClient.getInstance().getAuthUserToken()}`
                    }
                })
                .then((response) => {
                    if (response.data.success === false) {
                        throw new Error("could not upload file");
                    }
                    if (typeof onComplete === "function") {
                        onComplete(response.data.data);
                    }
                    setUploadError(false);
                })
                .catch((error) => {
                    if (typeof onError === "function") {
                        onError(error);
                    }
                    setUploadError(true);
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }
    };
    const fileSelect = () => {
        // if form is disabled exit
        if (isDisabled) {
            return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fileInputRef.current!.click();
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        setSelectedFile(file);
        // upload file
        if (file) {
            fileUpload(file);
        }
    };
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDrag(true);
    };
    const dragLeave = () => {
        setDrag(false);
    };
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        // if form is disabled exit
        if (isDisabled) {
            return false;
        }
        setDrag(false);
        const file = event.dataTransfer.files && event.dataTransfer.files[0];
        setSelectedFile(file);
        // upload file
        if (file) {
            fileUpload(file);
        }
    };
    return (
        <div
            className={`file-upload w-full relative ${isDisabled && "opacity-65"} overflow-hidden ${drag && !isDisabled && "border-blue-500 shadow-md shadow-blue-500"}`}
        >
            <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
            {selectedFile ? (
                <div className="relative overflow-hidden grid grid-cols-[auto_40px] max-w-[400px] rounded-[8px] border bg-[#F7F7F7] border-gray-200 py-[10px] px-[12px]">
                    <div className="flex gap-[8px]">
                        <div className="icon w-[30px]">
                            <FileIcon
                                extension={getFileNameAndExtension(selectedFile).extension}
                                type="document"
                                color="#f7f7f7"
                                labelColor="#3caf50"
                                {...defaultStyles[getFileNameAndExtension(selectedFile).extension]}
                            />
                        </div>
                        <div>
                            <p className="text-[15px] font-medium">
                                {truncateText(getFileNameAndExtension(selectedFile).name, 20)}.
                                {getFileNameAndExtension(selectedFile).extension}
                            </p>
                            <p className="text-[12px] font-normal text-slate-500">
                                {formatFileSize(selectedFile.size)}
                            </p>
                        </div>
                    </div>
                    <div className="actions flex items-center justify-end pr-[5px]">
                        {!isUploading && (uploadPercentage > 99 || uploadError) && (
                            <IconButton
                                variant={"ghost"}
                                size="sm"
                                aria-label="upload"
                                title="Upload another file"
                                onClick={fileSelect}
                            >
                                {uploadError ? (
                                    <TbRefreshAlert className="text-red-500" size="24px" />
                                ) : (
                                    <IoRefresh className="text-gray-900" size="20px" />
                                )}
                            </IconButton>
                        )}
                        {isUploading && <Spinner size={"sm"} />}
                    </div>
                    {isUploading && (
                        <div className="progress absolute left-0 bottom-0 right-0">
                            <Progress
                                value={uploadPercentage}
                                size="xs"
                                colorScheme="blue"
                                className="rounded-none"
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className="flex flex-col items-center gap-[6px] cursor-pointer rounded-[8px] border bg-[#F7F7F7] border-gray-200 py-[12px] px-[12px]"
                    onDragOver={handleDragOver}
                    onDragLeave={dragLeave}
                    onDrop={handleDrop}
                    onClick={fileSelect}
                >
                    <img src="/file.png" alt="file" className="h-[45px] w-[45px] inline-block" />
                    <p className="text-[13.5px] text-gray-600">
                        Select or Drag and drop a file to upload
                    </p>
                </div>
            )}
        </div>
    );
};
export default FileUploadControl;
