import { Spinner, Tag, TagLabel, Wrap, WrapItem } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import { IoGitNetwork } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/CollaborationGroupCard.component.scss";
import { CollaborationApp, CollaborationGroup } from "../../models/collaboration.model";
import { ROUTES } from "../../routes";
import {
    CollaborateState,
    setCollaborateViewMode,
    setSelectCollaborationApp,
    setIsAutoInteractionInitialized,
    setAutoInteractionId,
    clearBrainActivityMessages,
    clearAutoInteractionMessages,
    setAppMode
} from "../../store/slices/collaborate.slice";
import { useHttpClient } from "../../utils/http.utils";
// import { Field, Form, Formik, FormikHelpers } from "formik";
import { capitalizeWord } from "../../utils/strings.utils";
import { CloneCollaborationAppGroupInput } from "../modals/CloneCollaborationAppGroupModal";
import { DeleteConfirmation } from "../utils/ConfirmationModals";
// import KeycloakClient from "../../providers/auth/keycloak-client";

interface IdeationCardProps {
    data: CollaborationGroup;
}

export default function CollaborationGroupCard(props: IdeationCardProps) {
    const [showFiles, setShowFiles] = useState(false);
    const collaborate_state: CollaborateState = useSelector((state) => (state as any).collaborate);
    const navigate = useNavigate();
    const { deleteCollaborationGroupApi, cloneCollaborationAppApi, cloneCollaborationAppGroupApi } =
        useHttpClient();
    const [isCloning, setIsCloning] = useState(false);
    const [isCloningGroup, setIsCloningGroup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const selectedAppIndex = props.data?.collaboration_app_list?.findIndex(
            (app) => app.id === collaborate_state.selectedCollaborationApp?.id
        );
        if (selectedAppIndex > -1) {
            setShowFiles(true);
        }
    }, [collaborate_state.selectedCollaborationApp]);

    const deleteCollaborationGroup = () => {
        DeleteConfirmation({
            title: "Are you sure you want to delete this group? "
        }).then((isConfirmed) => {
            if (isConfirmed) {
                setIsDeleting(true);

                deleteCollaborationGroupApi(props.data.id).finally(() => setIsDeleting(false));
            }
        });
    };

    const cloneCollaborationApp = () => {
        setIsCloning(true);
        cloneCollaborationAppApi(props.data.id).finally(() => setIsCloning(false));
    };

    const cloneCollaborationAppGroup = () => {
        CloneCollaborationAppGroupInput({
            title: "Clone Collaboration App Group"
        }).then(({ title, description }) => {
            setIsCloningGroup(true);
            cloneCollaborationAppGroupApi(props.data.id, title, description).finally(() => {
                setIsCloningGroup(false);
            });
        });
    };

    const openPromptEngineering = async (e: any) => {
        e.preventDefault();
        navigate(
            `/${ROUTES.APPLY}/skills/prompt-engineering?collaboration_app_group_id=${props.data.id}`
        );
    };

    const renderTags = () => {
        // eslint-disable-next-line no-undef
        const tags: React.ReactElement[] = [];

        if (props.data.is_base) {
            tags.push(
                <Tag key="base" size={"sm"} variant="subtle" colorScheme="green">
                    <TagLabel fontSize={"12px"}>Base</TagLabel>
                </Tag>
            );
        }

        tags.push(
            <Tag
                key="type"
                size={"sm"}
                variant="subtle"
                colorScheme={props.data.type === "rigid" ? "purple" : "blue"}
            >
                <TagLabel fontSize={"12px"}>{capitalizeWord(props.data.type)}</TagLabel>
            </Tag>
        );

        return (
            <Wrap spacing={2}>
                {tags.map((tag, index) => (
                    <WrapItem key={index}>{tag}</WrapItem>
                ))}
            </Wrap>
        );
    };

    const renderActions = () => {
        if (props.data.is_base) {
            return (
                <div className="flex justify-between">
                    <button
                        onClick={cloneCollaborationAppGroup}
                        className="text-blue-500 font-semibold"
                    >
                        {isCloningGroup && <Spinner size="xs" mr={2} />}Clone
                    </button>
                </div>
            );
        } else {
            return (
                <div className="flex justify-between">
                    <div className="flex gap-2">
                        <button
                            onClick={cloneCollaborationAppGroup}
                            className="text-blue-500 font-semibold"
                        >
                            {isCloningGroup && <Spinner size="xs" mr={2} />}Clone
                        </button>
                        <button
                            onClick={cloneCollaborationApp}
                            className="text-blue-500 font-semibold"
                        >
                            {isCloning && <Spinner size="xs" mr={2} />}Create Application
                        </button>
                    </div>
                    <div className="ideation-card-action flex gap-1.5 justify-end">
                        <button className="text-red-500" onClick={deleteCollaborationGroup}>
                            {isDeleting ? (
                                <Spinner size="xs" mr={2} />
                            ) : (
                                <AiOutlineDelete className="icon text-red-500" />
                            )}
                        </button>
                        <IoGitNetwork onClick={openPromptEngineering} className="icon" />
                    </div>
                </div>
            );
        }
    };

    return (
        <div className="ideation-container">
            <div className="ideation-card">
                <div className="title-section">
                    <h3 className="title" title={props.data.title}>
                        {props.data.title}
                    </h3>

                    <div className="action">
                        <button onClick={() => setShowFiles(!showFiles)}>
                            {showFiles ? (
                                <BiChevronUp className="icon" />
                            ) : (
                                <BiChevronDown className="icon" />
                            )}
                        </button>
                    </div>
                </div>
                <div className="tag mt-[2px] mb-[4px]">{renderTags()}</div>
                <p className="description">{props.data.description}</p>

                {renderActions()}
            </div>
            <div className={`files-section ${showFiles ? "show" : ""}`}>
                {props.data?.collaboration_app_list?.map((app, index) => (
                    <CollaborationGroupAppCard key={index} app={app} groupId={props.data.id} />
                ))}
                {/* <Button size="sm" onClick={showCloneAppModal} leftIcon={<FiPlus/>}>Create</Button> */}
            </div>
            {/* <CloneCollaborationAppGroupModal
                appId={props.data.id}
                show={showCloneModal}
                onClose={() => setShowCloneModal(false)}
            /> */}
        </div>
    );
}

function CollaborationGroupAppCard({ app, groupId }: { app: CollaborationApp; groupId: string }) {
    const collaborate_state: CollaborateState = useSelector((state) => (state as any).collaborate);
    const {
        executeCollaborationAppApi,
        executeViewCollaborationAppApi,
        deleteCollaborationAppApi
        // updateInteractionGoalApi
    } = useHttpClient();
    const dispatch = useDispatch();

    const [isDeleting, setIsDeleting] = useState(false);

    const loadSelectedApp = (app: CollaborationApp) => {
        dispatch(setSelectCollaborationApp(app));
        dispatch(setCollaborateViewMode("initialize"));
        dispatch(setIsAutoInteractionInitialized(true));
        dispatch(setAutoInteractionId(app.auto_interaction));
        dispatch(clearBrainActivityMessages());
        dispatch(clearAutoInteractionMessages());

        executeCollaborationAppApi(app.id);
        dispatch(setAppMode("interactive"));
    };

    const viewSelectedApp = (app: CollaborationApp) => {
        dispatch(setSelectCollaborationApp(app));
        dispatch(setCollaborateViewMode("initialize"));
        dispatch(setIsAutoInteractionInitialized(true));
        dispatch(setAutoInteractionId(app.auto_interaction));
        dispatch(clearBrainActivityMessages());
        dispatch(clearAutoInteractionMessages());

        executeViewCollaborationAppApi(app.id);
        dispatch(setAppMode("view"));
    };

    const deleteCollaborationApp = (app: CollaborationApp) => {
        DeleteConfirmation({
            title: "Are you sure you want to delete this app? "
        }).then((isConfirmed) => {
            if (isConfirmed) {
                setIsDeleting(true);
                deleteCollaborationAppApi(groupId, app.id).finally(() => setIsDeleting(false));
            }
        });
    };

    // const updateInteractionGoal = async (id: string, goal: string) => {
    //     await updateInteractionGoalApi(id, goal);
    //     // TODO: REVERSE GOAL IF UPDATE FAILS
    // };

    return (
        <div
            className={`file-row mb-2 ${collaborate_state.selectedCollaborationApp?.id === app.id ? "active" : ""}`}
        >
            <p className="file-name" title={app.title}>
                {app.title}
            </p>
            <p className="file-description" title={app.description}>
                {app.description} .. <span className="text-blue-500 font-semibold">Learn more</span>
            </p>
            {app.json_structure?.cells?.length > 0 && (
                <ul className="goals-list">
                    <li className="goal-title">Goals</li>
                    {app.json_structure?.cells?.map((cell, index) => (
                        <li key={`cell-index-${index}`}>
                            <p className="py-2 font-medium">{cell.goal}</p>
                        </li>
                    ))}
                </ul>
            )}
            <div className="actions">
                {app.status === "completed" && (
                    <button onClick={() => viewSelectedApp(app)} className="default btn">
                        <span>View</span>
                    </button>
                )}

                {(app.status === "active" ||
                    app.status === "initial" ||
                    app.status === "ready_to_run") && (
                    <button onClick={() => loadSelectedApp(app)} className="default btn">
                        <span>Resume</span>
                    </button>
                )}

                <button onClick={() => deleteCollaborationApp(app)} className="danger btn">
                    {isDeleting ? (
                        <Spinner className="text-red-500" size="xs" mr={2} />
                    ) : (
                        <AiOutlineDelete className="icon text-red-500" />
                    )}
                </button>
                <button onClick={() => loadSelectedApp(app)}>
                    <FiChevronRight className={"icon"} />
                </button>
            </div>
        </div>
    );
}

// function CloneCollaborationAppGroupModal(props: {
//     appId: string;
//     show: boolean;
//     onClose?: () => void;
// }) {
//     const { isOpen, onOpen, onClose } = useDisclosure();
//     const [cloneStatus, setCloneStatus] = useState<
//         "loading" | "complete" | "failed"
//     >("loading");
//     const { cloneCollaborationAppGroupApi } = useHttpClient();
//     const [collaborationGroup, setCollaborationGroup] =
//         useState<CollaborationGroup | null>(null);
//     const toast = useToast();
//     const { updateCollaborationGroupApi } = useHttpClient();

//     useEffect(() => {
//         if (props.appId && props.show && !collaborationGroup) {
//             setCloneStatus("loading");
//             cloneCollaborationAppGroupApi(props.appId).then(({ data }) => {
//                 console.log(data["collaboration_app_group"]);
//                 if (data.success === false) {
//                     toast({
//                         title: "Cannot clone this app at the moment",
//                         description: "Please try again later",
//                         status: "error",
//                     });
//                     setCloneStatus("failed");
//                     return;
//                 }
//                 /**
//                  * Else show form
//                  */
//                 setCollaborationGroup(data["collaboration_app_group"]);
//                 setCloneStatus("complete");
//             });
//         }
//     }, [props]);

//     useEffect(() => {
//         if (props.show) {
//             onOpen();
//         }
//     }, [props.show]);

//     const close = () => {
//         onClose();
//         if (typeof props.onClose === "function") {
//             props.onClose();
//         }
//     };

//     function validateRequired(key, value) {
//         let error;

//         if (!value) {
//             error = `${key} is required`;
//         }
//         return error;
//     }

//     const saveCollaborationClone = async (
//         values: any,
//         actions: FormikHelpers<any>,
//     ) => {
//         console.log(values);
//         updateCollaborationGroupApi(
//             (collaborationGroup as CollaborationGroup).id,
//             values,
//         )
//             .then(() => close())
//             .finally(() => {
//                 actions.setSubmitting(false);
//             });
//     };

//     return (
//         <Modal
//             size="lg"
//             closeOnOverlayClick={false}
//             isCentered={true}
//             isOpen={isOpen}
//             onClose={close}
//         >
//             <ModalOverlay />
//             <ModalContent>
//                 {cloneStatus === "loading" && (
//                     <>
//                         <ModalHeader>
//                             <span className="text-lg">Clone Application</span>
//                         </ModalHeader>
//                         <ModalBody>
//                             <div className="clone-loading-box">
//                                 <Spinner size="lg" />
//                                 <h2 className="text-sm mt-4">
//                                     Setting up new clone...
//                                 </h2>
//                             </div>
//                         </ModalBody>
//                     </>
//                 )}
//                 {cloneStatus === "failed" && (
//                     <>
//                         <ModalHeader>
//                             <span className="text-lg">Clone Application</span>
//                         </ModalHeader>
//                         <ModalBody>
//                             <div className="clone-loading-box">
//                                 <img
//                                     src={"/failed_clone.png"}
//                                     className="icon"
//                                 />
//                                 <h2 className="text-sm mt-4">
//                                     Unable to clone this group at the moment.
//                                     Please try again later
//                                 </h2>
//                             </div>
//                         </ModalBody>
//                     </>
//                 )}
//                 {cloneStatus === "complete" && (
//                     <Formik
//                         initialValues={{
//                             title: collaborationGroup?.title,
//                             description: collaborationGroup?.description,
//                             goal_list:
//                                 collaborationGroup?.collaboration_app_list[0].collaboration_app_cell_list.map(
//                                     (cell) => cell.goal,
//                                 ),
//                         }}
//                         onSubmit={saveCollaborationClone}
//                     >
//                         {(props) => (
//                             <Form>
//                                 <ModalHeader>Clone Application</ModalHeader>
//                                 <ModalCloseButton />
//                                 <ModalBody>
//                                     <Field
//                                         name="title"
//                                         validate={(e) =>
//                                             validateRequired("Title", e)
//                                         }
//                                     >
//                                         {({ field, form }) => (
//                                             <FormControl
//                                                 mb={3}
//                                                 isInvalid={
//                                                     form.errors.title &&
//                                                     form.touched.title
//                                                 }
//                                             >
//                                                 <FormLabel>Title</FormLabel>
//                                                 <Input
//                                                     {...field}
//                                                     placeholder="Enter title"
//                                                 />
//                                                 <FormErrorMessage>
//                                                     {form.errors.title}
//                                                 </FormErrorMessage>
//                                             </FormControl>
//                                         )}
//                                     </Field>
//                                     <Field
//                                         name="description"
//                                         validate={(e) =>
//                                             validateRequired("Description", e)
//                                         }
//                                     >
//                                         {({ field, form }) => (
//                                             <FormControl
//                                                 mb={3}
//                                                 isInvalid={
//                                                     form.errors.description &&
//                                                     form.touched.description
//                                                 }
//                                             >
//                                                 <FormLabel>
//                                                     Description
//                                                 </FormLabel>
//                                                 <Textarea
//                                                     {...field}
//                                                     placeholder="Description"
//                                                     rows={6}
//                                                 ></Textarea>
//                                                 <FormErrorMessage>
//                                                     {form.errors.description}
//                                                 </FormErrorMessage>
//                                             </FormControl>
//                                         )}
//                                     </Field>
//                                     {collaborationGroup
//                                         ?.collaboration_app_list[0] && (
//                                         <div className="goal-section mt-4">
//                                             <h3 className="text-sm font-semibold">
//                                                 Goals
//                                             </h3>
//                                             {collaborationGroup?.collaboration_app_list[0].collaboration_app_cell_list?.map(
//                                                 (cell, index) => (
//                                                     <Field
//                                                         name={`goal_list[${index}]`}
//                                                         validate={(e) =>
//                                                             validateRequired(
//                                                                 "This goal",
//                                                                 e,
//                                                             )
//                                                         }
//                                                     >
//                                                         {({ field, form }) => (
//                                                             <FormControl
//                                                                 mb={3}
//                                                                 isInvalid={
//                                                                     form.errors
//                                                                         .goal_list[
//                                                                         index
//                                                                     ] &&
//                                                                     form.touched
//                                                                         .goal_list[
//                                                                         index
//                                                                     ]
//                                                                 }
//                                                             >
//                                                                 <Input
//                                                                     {...field}
//                                                                     placeholder="Description"
//                                                                 ></Input>
//                                                                 <FormErrorMessage>
//                                                                     {
//                                                                         form
//                                                                             .errors
//                                                                             .goal_list[
//                                                                             index
//                                                                         ]
//                                                                     }
//                                                                 </FormErrorMessage>
//                                                             </FormControl>
//                                                         )}
//                                                     </Field>
//                                                 ),
//                                             )}
//                                             {collaborationGroup
//                                                 ?.collaboration_app_list[0]
//                                                 .collaboration_app_cell_list
//                                                 .length === 0 && (
//                                                 <div className="flex items-center justify-center h-20">
//                                                     <p className="font-semibold text-sms">
//                                                         No Goals to display
//                                                     </p>
//                                                 </div>
//                                             )}
//                                         </div>
//                                     )}
//                                 </ModalBody>

//                                 <ModalFooter>
//                                     <ButtonGroup>
//                                         {cloneStatus === "complete" && (
//                                             <Button
//                                                 loadingText="Saving.."
//                                                 type="submit"
//                                                 colorScheme="brand"
//                                                 isLoading={props.isSubmitting}
//                                             >
//                                                 Save
//                                             </Button>
//                                         )}
//                                         <Button onClick={close}>Cancel</Button>
//                                     </ButtonGroup>
//                                 </ModalFooter>
//                             </Form>
//                         )}
//                     </Formik>
//                 )}
//             </ModalContent>
//         </Modal>
//     );
// }
