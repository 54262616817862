import { Button, Checkbox } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PersonaModel, PersonaTransaction } from "../../models/persona.model";
import { PersonaState } from "../../store/slices/persona.slice";
import { useHttpClient } from "../../utils/http.utils";
import { formatReadableNumber } from "../../utils/number.utils";
import UsageChart from "../UsageChart";
import PersonaFinanceChart from "./PersonaFinanceChart";

// TODO: move to models folder
type PersonaPerformanceProps = {
    moveToNext?: () => void;
    moveToPrevious?: () => void;
    persona?: PersonaModel;
};

// type FinanceChartData = { x: string; y: number }[];

const PersonaPerformance = ({ persona }: PersonaPerformanceProps) => {
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const { updatePersonaApi, getPersonaBalanceApi, getPersonaTransactionApi, performCashOutApi } =
        useHttpClient();
    const [personaTransactions, setPersonaTransactions] = useState<PersonaTransaction[]>([]);
    const [personaBalance, setPersonaBalance] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (persona?.id) {
            getPersonaTransactionApi(
                persona.id,
                { currency_type: "arin_system_credit" },
                true
            ).then((data) => {
                if (data) {
                    setPersonaTransactions(data);
                }
            });
        } else {
            setPersonaTransactions([]);
        }
    }, [persona]);

    useEffect(() => {
        if (persona?.id) {
            getPersonaBalanceApi(persona.id).then((data) => {
                setPersonaBalance(data?.arin_system_credit_balance || 0);
            });
        } else {
            setPersonaBalance(0);
        }
    }, []);

    const updatePersona = async (data: PersonaModel) => {
        if (persona?.id) {
            setIsLoading(true);
            await updatePersonaApi(data);
            setIsLoading(false);
        }
    };

    const onPerformCashOut = async (_data: PersonaModel) => {
        if (persona?.id) {
            setIsLoading(true);
            const cashOutSuccess = await performCashOutApi(persona.id);

            if (cashOutSuccess) {
                setPersonaBalance(0);
            }

            setIsLoading(false);
        }
    };

    return (
        <div className="mt-5">
            {personaState.personaAttributesForm.type !== "entity" && (
                <div className="mb-10">
                    <h1 className="mb-[7px] leading-[1] font-medium text-[16px]">Dashboard</h1>
                    <p className="mb-6 text-[13px] text-gray-500">
                        The total revenue made by your Ai entity and expenditure from your team.
                    </p>
                    <div className="mt-10">
                        {/* <h1 className=' text-xl font-bold my-6'>Finance</h1> */}
                        <PersonaFinanceChart data={personaTransactions} persona={persona} />
                    </div>
                    <div>
                        <div className="ml-8 mr-6 mt-6 rounded border p-4 mb-4">
                            <span className="text-slate-500 text-[12px]">BALANCE</span>
                            <h1 className="mb-5 mt-1 text-2xl font-medium">
                                T$ {formatReadableNumber(personaBalance, 4)}
                            </h1>
                            <Button
                                colorScheme="brand"
                                fontSize={"13px"}
                                fontWeight="400"
                                isDisabled={personaBalance <= 0}
                                isLoading={isLoading}
                                onClick={onPerformCashOut}
                            >
                                Cash Out
                            </Button>
                        </div>

                        <p className="w-[800px] ml-10">
                            It will take 48 hours to credit your account. Please note that you are
                            responsible for the fees from the ethereum and talos network for the
                            transfer. Approximately: <b>$17.00</b> at current time of processing. If
                            the gas fees get too high at the time of transfer, we will return the
                            amount to you here.
                        </p>

                        <div className="ml-10 mt-4">
                            <Checkbox
                                colorScheme="blue"
                                isChecked={persona?.use_profit === "yes"}
                                isDisabled={isLoading}
                                onChange={() =>
                                    updatePersona({
                                        id: persona?.id,
                                        use_profit: persona?.use_profit === "yes" ? "no" : "yes"
                                    })
                                }
                            >
                                <p className="text-[14px] file:my-5 w-[800px]">
                                    Offset my payment. This will use your profit to pay for your
                                    platform usage or next month's expenses with NeoWorlder. There
                                    are no fees in this process.
                                </p>
                            </Checkbox>
                        </div>
                    </div>
                </div>
            )}
            <div className="">
                <UsageChart title="Interactions" persona={persona} />
            </div>
        </div>
    );
};

export default PersonaPerformance;
