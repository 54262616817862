// ModalContext.js
import { ReactElement, createContext, useContext, useState } from "react";

interface IGenericModalContext {
    openPaywallModal: () => void;
    closePaywallModal: () => void;
    isPaywallOpen: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const GenericModalContext = createContext<IGenericModalContext>();

export const useGenericModal = () => useContext(GenericModalContext);

export const GenericModalProvider = ({
    children
}: {
    children: ReactElement<any> | ReactElement<any>[];
}) => {
    const [isPaywallOpen, setIsPaywallOpen] = useState(false);

    const openPaywallModal = () => {
        setIsPaywallOpen(true);
    };

    const closePaywallModal = () => {
        setIsPaywallOpen(false);
    };

    return (
        <GenericModalContext.Provider
            value={{
                openPaywallModal,
                closePaywallModal,
                isPaywallOpen
            }}
        >
            {children}
        </GenericModalContext.Provider>
    );
};
