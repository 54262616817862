import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/account.slice";
import authReducer from "./slices/auth.slice";
import chatReducer from "./slices/chat.slice";
import collaborateReducer from "./slices/collaborate.slice";
import folderReducer from "./slices/folder.slice";
import uploadedDataReducer from "./slices/memories.slice";
import paymentMethodReducer from "./slices/payment-method.slice";
import personaReducer from "./slices/persona.slice";
import searchesReducer from "./slices/searches.slice";
import templateReducer from "./slices/template.slice";
import threadReducer from "./slices/threads.slice";
import userQueriesReducer from "./slices/user-queries.slice";
// import { userQueriesApi } from "./services/userQueries.service";

export default configureStore({
    reducer: {
        folders: folderReducer,
        collaborate: collaborateReducer,
        persona: personaReducer,
        searches: searchesReducer,
        userQueries: userQueriesReducer,
        uploadedData: uploadedDataReducer,
        auth: authReducer,
        account: accountReducer,
        threads: threadReducer,
        chat: chatReducer,
        paymentMethod: paymentMethodReducer,
        template: templateReducer
        // [userQueriesApi.reducerPath]: userQueriesApi.reducer,
    }
    // Adding the api middleware enables caching, invalidation, polling,
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware().concat([userQueriesApi.middleware]),
});
