import { FormControl, IconButton, Switch } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useEffect, useState, useMemo } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import Select from "react-select";
import ArinLayout from "../../components/ArinLayout";
import "../../styles/ApplicationsView.component.scss";
import ConfigureSkill from "../../components/global/ConfigureSkill";
import { PersonaState } from "../../store/slices/persona.slice";
import { useHttpClient } from "../../utils/http.utils";

export default function GlobalView() {
    const { fetchCollaborationAppGroupApi } = useHttpClient();
    const [skillTemplates, setSkillTemplates] = useState<any[]>([]);
    const [collabGroups, setCollabGroups] = useState<any[]>([]);
    const [selectedSkillTemplate, setSelectedSkillTemplate] = useState<string>("");
    const [selectedPersona, setSelectedPersonas] = useState<any[]>([]);
    const [selectedBaseSkill, setSelectedBaseSkill] = useState<any | null>(null);
    const [selectedCloneSkills, setSelectedCloneSkills] = useState<any[]>([]);
    const [showDescription, setShowDescription] = useState(false);
    const [formMode, setFormMode] = useState<"edit" | "none">("none");
    const [replace, setReplace] = useState<boolean>(false);

    const personaState: PersonaState = useSelector((state: any) => state.persona);

    useEffect(() => {
        const fetchRigidSkills = async () => {
            const data = await fetchCollaborationAppGroupApi({
                search_criteria: {
                    is_base: true
                }
            });

            setSkillTemplates(
                data.map((collabGroup): any => ({
                    ...collabGroup,
                    value: collabGroup.id,
                    label: collabGroup.title
                }))
            );
        };

        fetchRigidSkills();
    }, []);

    useEffect(() => {
        const fetchCollabGroups = async () => {
            const data = await fetchCollaborationAppGroupApi({
                search_criteria: {
                    is_base: false,
                    rigid_app_code: selectedSkillTemplate,
                    ...(selectedPersona && selectedPersona.length > 0
                        ? { persona: selectedPersona }
                        : {})
                }
            });

            setCollabGroups(
                data.map((collabGroup): any => ({
                    ...collabGroup,
                    value: collabGroup.id,
                    label: collabGroup.title
                }))
            );

            if (selectedBaseSkill) {
                setFormMode("edit");
            } else {
                setFormMode("none");
            }
        };

        fetchCollabGroups();
    }, [selectedSkillTemplate, selectedPersona]);

    const personaSelectOptions = useMemo(() => {
        const options = personaState.personas
            .map((persona) => {
                const value = persona?.id ?? "";
                const label = `${persona.first_name ?? ""} ${persona.last_name ?? ""}`.trim();
                const type = persona?.type ?? "";
                const category = "persona";
                return { value, label, type, category };
            })
            .filter((option) => option.value && option.label);

        return options;
    }, [personaState.personas]);

    return (
        <ArinLayout>
            <div className="three-col-layout">
                <div className="grid-column pl-2 pb-3">
                    <div className="sidebar-sm relative">
                        <div className="side-nav-sm">
                            <p className="title">Configure Skills</p>
                            <p className="subtitle">Update Global Skill Arguments</p>
                        </div>
                        <div className="sidebar-body">
                            <Formik
                                initialValues={{ skills: [], personas: [] }}
                                onSubmit={() => {
                                    // do nothing
                                }}
                            >
                                {() => (
                                    <Form>
                                        <FormControl mb={6}>
                                            <p className="text-[14px] mb-[2px]">
                                                Select Base Skill
                                            </p>
                                            <Field name="personas">
                                                {() => (
                                                    <Select
                                                        placeholder="Select base skill..."
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                        options={skillTemplates}
                                                        onChange={(selectedOption: any) => {
                                                            setSelectedSkillTemplate(
                                                                selectedOption.rigid_app_code
                                                            );
                                                            setSelectedBaseSkill(
                                                                skillTemplates.find(
                                                                    (skill) =>
                                                                        skill.id ===
                                                                        selectedOption.id
                                                                )
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormControl>

                                        <FormControl mb={6}>
                                            <p className="text-[14px] mb-[2px]">Select Personas</p>
                                            <Field name="personas">
                                                {({ field, form }) => (
                                                    <Select
                                                        isMulti
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                        isClearable={false}
                                                        placeholder="Select personas"
                                                        name="personas"
                                                        value={personaSelectOptions.filter(
                                                            (option) =>
                                                                field.value.some(
                                                                    (val) => val === option.value
                                                                )
                                                        )}
                                                        onChange={(selectedOptions) => {
                                                            const modifiedData = (
                                                                selectedOptions || []
                                                            ).map((option) => option.value);
                                                            form.setFieldValue(
                                                                "personas",
                                                                modifiedData
                                                            );
                                                            setSelectedPersonas(modifiedData);
                                                        }}
                                                        onBlur={field.onBlur}
                                                        options={personaSelectOptions}
                                                        isDisabled={selectedSkillTemplate === ""}
                                                    />
                                                )}
                                            </Field>
                                        </FormControl>

                                        <FormControl mb={6}>
                                            <p className="text-[14px] mb-[2px]">
                                                Select Cloned Skills
                                            </p>
                                            <Field name="skills">
                                                {({ field, form }) => (
                                                    <Select
                                                        isMulti
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                        isClearable={false}
                                                        placeholder={
                                                            collabGroups.length === 0
                                                                ? "No options available"
                                                                : "Select clone skills"
                                                        }
                                                        name="cloneSkills"
                                                        value={collabGroups.filter((option) =>
                                                            field.value.some(
                                                                (val) => val === option.value
                                                            )
                                                        )}
                                                        onChange={(selectedOptions) => {
                                                            const modifiedData = (
                                                                selectedOptions || []
                                                            ).map((option) => option.value);
                                                            setSelectedCloneSkills(modifiedData);
                                                            form.setFieldValue(
                                                                "skills",
                                                                modifiedData
                                                            );
                                                        }}
                                                        onBlur={field.onBlur}
                                                        options={collabGroups}
                                                        isDisabled={collabGroups.length === 0}
                                                    />
                                                )}
                                            </Field>
                                        </FormControl>

                                        <FormControl mb={6}>
                                            <div className="flex gap-[5px] items-center">
                                                <p className="text-[14px] mb-[2px]">
                                                    Replace Values
                                                </p>
                                                <IconButton
                                                    icon={
                                                        <IoIosInformationCircleOutline size="18px" />
                                                    }
                                                    aria-label={"Description"}
                                                    title="Show Description"
                                                    className="cursor-pointer"
                                                    size="xs"
                                                    variant={"ghost"}
                                                    onClick={() =>
                                                        setShowDescription(!showDescription)
                                                    }
                                                />
                                            </div>
                                            {showDescription && (
                                                <p className="text-[13px] mt-[4px] font-normal text-neutral-400">
                                                    By default, the updated data is appended to the
                                                    existing text fields. Enabling this option will
                                                    replace the current text values with the updated
                                                    ones (without affecting other types of data).
                                                </p>
                                            )}

                                            <Field name="replace">
                                                {({ field: replaceField, form: replaceForm }) => (
                                                    <Switch
                                                        id="replaceSwitch"
                                                        name="replace"
                                                        checked={replaceField.value}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setReplace(isChecked);
                                                            replaceForm.setFieldValue(
                                                                "replace",
                                                                isChecked
                                                            );
                                                        }}
                                                        className="w-5 h-5"
                                                    />
                                                )}
                                            </Field>
                                        </FormControl>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="main grid-column">
                    {formMode === "none" && (
                        <div className="empty-state">
                            <div className="empty-content">
                                <div className="title">No skill available for display</div>
                            </div>
                        </div>
                    )}

                    {formMode === "edit" && (
                        <div className="personas-body pl-4" style={{ overflowY: "auto" }}>
                            <div>
                                <ConfigureSkill
                                    collab={selectedBaseSkill}
                                    selectedPersona={selectedPersona}
                                    selectedSkills={selectedCloneSkills}
                                    replaceValue={replace}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ArinLayout>
    );
}
